import { Component, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WishlistStore } from 'src/app/@core/store/wishlist.store';
import { CartService } from 'src/app/services/cart.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-dynamic-courses-list',
  templateUrl: './dynamic-courses-list.component.html',
  providers: [CartService]
})

export class DynamicCoursesListComponent {

  constructor(
    public router: Router,
    public render: Renderer2,
    public sharedService: SharedService,
    public cartService: CartService,
    public wishlistStore: WishlistStore
  ) {

  }

  sendToCart() {
    this.wishlistStore.state.courses.map((course: any) => {
      this.cartService.addItem(
        course.id,
        'trail',
        course.arquivo,
        course.titulo,
        (course.price) ? (course.price.ValorTotal) : 0,
        1,
        course.price.checkout
      );
    });

    this.wishlistStore.reset();
    this.closeSidebar();

    this.router.navigate(['/shopping-cart']);
  }

  closeSidebar() {
    const sidebarEl = document.querySelector('.dynamicSidebar');
    const overlayEl = document.querySelector('.dynamicOverlay');
    // document.body.style.overflow = 'scroll';

    this.render.removeClass(sidebarEl, 'active');
    setTimeout(() => {
        this.render.removeClass(overlayEl, 'active');
    }, 200);

    this.sharedService.nextData(null);
  }

}
