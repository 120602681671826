import { Component } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';

@Component({
    selector: 'app-dynamic-address',
    templateUrl: './dynamic-address.component.html',
})

export class DynamicAddressComponent {

    constructor(
        public sharedService: SharedService
    ) {
        
    }

}
