import { Component, OnInit, OnDestroy } from '@angular/core';
import { Global } from 'asap-crud';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { BrowserNotificationsService, PushNotification } from 'src/app/services/browser-notification.service';
import { AsapClientPipe } from 'asap-crud';

@Component({
    selector: 'app-messenger-notification',
    templateUrl: './messenger-notification.component.html',
    styleUrls: ['./messenger-notification.component.scss'],
    providers: [ BrowserNotificationsService, AngularFirestore ]
})
export class MessengerNotificationComponent implements OnInit, OnDestroy {

    public dictionary = AsapClientPipe.dictionary;

    public keepAlive;

    public users: any;
    public usersNotifications: any;

    constructor(
        public global: Global,
        public firestore: AngularFirestore,
        public notification: BrowserNotificationsService
    ) {
        if (this.global.loggedUser()) {
            this.users = firestore.collection(`/history/unread/user_` + this.global.loggedUser().id, (ref) => ref.where('total', '>', 0).limit(5)).valueChanges();
            this.users.subscribe((item:any) => {
                if (item && item.length) {
                    let data = [];
                    let noti:any = {};

                    noti.title = item[0].user_name;
                    noti.alertContent = item[0].lastMessage;
                    noti.icon = item[0].picture;
                    data.push(noti);
                    this.notification.generateNotification(data);
                }
            });

            let globalUser = this.global.loggedUser();
            let user = 'user_' + globalUser.id;

            firestore.doc(`/status/` + user).set({
                user_name: globalUser.name,
                picture: globalUser.picture,
                uid: globalUser.id,
                updated_at: Date.now(),
                status: true
            });
        }
    }

    ngOnInit() {
        if (!this.keepAlive) {
            this.keepAlive = setInterval(() => {
                if (this.global.loggedUser()) {
                    let globalUser = this.global.loggedUser();
                    let user = 'user_' + globalUser.id;

                    this.firestore.doc(`/status/` + user).set({
                        user_name: globalUser.name,
                        picture: globalUser.picture,
                        uid: globalUser.id,
                        updated_at: Date.now(),
                        status: true
                    });
                }
            },120000)
        }
    }

    ngOnDestroy() {
        if (!this.keepAlive) {
            clearInterval(this.keepAlive);
        }
    }

}
