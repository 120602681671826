import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VoucherStore } from '../store/voucher.store';

@Injectable()
export class ClientInterceptor implements HttpInterceptor {

    constructor(public voucherStore: VoucherStore) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const clientID = this.getClientByReqAndVoucher(req.url);
        console.log("[intercept]",clientID);
        let changeReq = req.clone();
        if (clientID) {
            if (!req.params.get('client_id')) {
                changeReq = req.clone({
                    params: req.params.append('client_id', clientID)
                });
            }
        }
        return next.handle(changeReq);
    }

    getClientByReqAndVoucher(url: string): string {
        let clientID = null;
        if (this.isLoginOrRegister(url) && this.voucherStore.state.isValid) {
            clientID = '' + this.voucherStore.state.voucher.client_id;
        }
        if (!clientID) {
            clientID = localStorage.getItem('client');
        }
        return clientID;
    }

    isLoginOrRegister(url: string): boolean {
        const loginUri = 'oauth/access_token';
        const registerUri = 'v2/client/register';
        const socialUri = 'social_auth/grupo';
        
        return (url.includes(loginUri) || url.includes(registerUri) || url.includes(socialUri));
    }

}
