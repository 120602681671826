import { Component } from '@angular/core';

@Component({
  selector: 'app-market-footer',
  templateUrl: 'market-footer.component.html'
})

export class MarketFooterComponent {
	test: Date = new Date();
}
