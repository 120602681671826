import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SharedService {
    private data = new BehaviorSubject<any>({});
    sharedData = this.data.asObservable();

    constructor() { }

    nextData(message: any) {
        this.data.next(message);
    }
}
