import { Injectable } from '@angular/core';
import { BaseService } from 'asap-crud';
import { Global } from 'asap-crud';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';

declare const $: any;

@Injectable()
export class ThemeService extends BaseService {

    public mainPage = 'start';
    public showHome = true;
    public theme;
    public themeLoaded;
    public disableCheckout;
    public _waiting;

    constructor(
        public http: HttpClient,
        public global: Global,
        public router: Router
    ) {
        super(http);
        this.basePath = 'api/v2';
        this.entityName = '';
    }

    public set waiting(value) {
        this._waiting = value;
        if (value) {
            $('html, body, window, .main-panel').scrollTop(0);
            $('html').css('overflow','hidden');
        }
        else {
            $('html').css('overflow','auto');
        }
    }

    public get waiting() {
        return this._waiting;
    }
    
    public useMainPage() {
        console.log("[useMainPage]", this.theme.config, ('mainPage' in this.theme.config))
        if ('mainPage' in this.theme.config) {
            return true;
        }

        return false;
    }

    public hasPlatform(platform: string): boolean {
        return this.theme.config.template.platforms.includes(platform);
    }

    getColorByHost(host) {
        const data = { url: host };
        return this.createResource(data, {router: 'admin/color/host'});
    }

    getDesign(type=0) {
        let color:any = localStorage.getItem("config_color");
        if (color) {    
            color = JSON.parse(color);
            if (color.layout) {
                let layout = color.layout.find(l => l.status == type);
                if (layout) {
                    return of({data: layout});
                }
            }
        }
        const params = { router: 'client/type/layout/open', query: 'type=' + type };
        return this.getResources(params);
    }
    

}
