import { Component } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-box-contributors',
    templateUrl: './box-contributors.component.html',
    styleUrls: ['./box-contributors.component.css'],
    providers: [ UserService ]
})

export class BoxContributorsComponent {

    public topUsers;

    constructor(
        public userService: UserService
    ) {
        console.log('1', Date());
        this.userService.topUsers().subscribe((response:any) => {
            this.topUsers = response;
            console.log("topUsers",this.topUsers);
        });
        console.log('2', Date());
    }
}
