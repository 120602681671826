import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/services/shared.service';
import { GroupService } from 'src/app/services/group.service';
import { SessionService } from 'src/app/services/session.service';
import { AuthService } from 'src/app/services/auth.service';
import { VoucherStore } from 'src/app/@core/store/voucher.store';
import { AsapBaseCRUD, AsapService, Global } from 'asap-crud';
import { Router } from '@angular/router';
import { ModalTermsComponent } from '../register/modal-terms/modal-terms.component';
import { MatDialog } from '@angular/material/dialog';
import { CaptchaService } from 'src/app/services/captcha.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-dynamic-lobby',
  templateUrl: './dynamic-lobby.component.html',
  providers: [ CaptchaService ]
})

export class DynamicLobbyComponent extends AsapBaseCRUD implements OnInit {
  @Output() closeSidebarEvent = new EventEmitter();

  profiles$: Observable<any>;
  entity: any;

  public captcha: any;

  constructor(
    public groupService: GroupService,
    public sessionService: SessionService,
    public sharedService: SharedService,
    public voucherStore: VoucherStore,
    public auth: AuthService,
    public global: Global,
    public router: Router,
    public service: AsapService,
    public dialog: MatDialog,
    public captchaService: CaptchaService

  ) {
    super(global, service);
    this.service.basePath = 'api/v2/client';
    this.service.entityName = 'cad';
    this.editBootstrap = true;
    this.editClass = '';
    this.add();

    this.entity = {
      name: '',
      email: '',
      password: '',
      confirm_password: '',
      checked: false,
      field: null,
      value: null
    };

    this.captchaService.getCaptcha().subscribe((response) => {
      this.captcha = response;
      this.entity.code = this.captcha.code;
    });
  }

  ngOnInit() {
    this.getProfiles();
  }

  getProfiles() {
    this.profiles$ = this.groupService.getResources().pipe(map((data: any) => this.profilesFilter(data)));
  }

  profilesFilter(data: any) {
    return data.filter((item: any) => item.status === 1);
  }

  goToRegister(profile: any) {
    this.sessionService.setItem('profileToRegister', profile);
    this.sharedService.nextData('isRegister');
  }

  register() {
    console.log('save: this.entity', this.entity);

    if (this.entity.password === this.entity.confirm_password) {

      if (this.voucherStore.state.isValid) {
        this.entity.field = this.voucherStore.state.voucher.field;
        this.entity.value = this.voucherStore.state.voucher.value;
        this.entity.voucher_id = this.voucherStore.state.voucher.voucher_id;
      }

      super.save(this.entity,
        (response: any) => {
          this.auth.doLogon(this.entity).then((success) => {
            this.closeSidebarEvent.emit();
          });
        },
        null,
        (error: any) => {
          if (error.success === false) {
            this.global.alert('Erro...', error.message, 'error');
            this.captcha = error.captcha;
            this.entity.code = this.captcha.code;
            this.entity.captcha = null;
          }
          this.router.navigate(['/login']);
        }
      );
    } else {
      this.global.notification.error('Senha...', 'O campo senha e confirmação devem ser iguais!');
    }
  }

  openModalWithTerms() {
    this.dialog.open(ModalTermsComponent, {
      width: '600px',
    });
  }


}
