import { Component, OnInit } from '@angular/core';
import { AsapService, Global } from 'asap-crud';


@Component({
  selector: 'app-voucher-data-modal-login',
  templateUrl: './voucher-data-modal-login.component.html',
  styleUrls: ['./voucher-data-modal-login.component.scss']
})
export class VoucherDataModalLoginComponent implements OnInit {
  public isValidateVoucher = false;
  public error:any = {};
  public entity:any = {}
  public loading = false;
  constructor(
    public service: AsapService,
    public global: Global
  ) {
    this.service.basePath = 'api/v2/client';
   }

  ngOnInit() {
  }
  
  validate() {
    this.loading = true;
    this.service.entityName = 'voucher/cad/validate';
    this.service.createResource(this.entity).subscribe(response => {
      if (response.success === true) {
        this.error = {};
        this.isValidateVoucher = true;
      }
      else {
        this.error = response;
      }
      this.loading = false;
      console.log(response);
    });
  }

}
