import { Component } from '@angular/core';

@Component({
  selector: 'app-school-search',
  template: `<div class="container" id="general-search-container"><asap-general-search></asap-general-search></div>`
})

export class SchoolSearchComponent {

}
