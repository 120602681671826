import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import localePt from '@angular/common/locales/pt';
import { ThemeService } from './services/theme.service';
import { PlatformGuardService } from './services/platform-guard.service';
import { ClientInterceptor } from './@core/interceptors/client-interceptor';
import { NavigationCancel, NavigationEnd, NavigationStart, Router, RouterModule, RoutesRecognized } from '@angular/router';
import { AsapClientPipe, AsapCrudModule, AsapService, Global } from 'asap-crud';
import { AppRoutes } from './app.routing';
import { AuthModule } from './auth/auth.module';
import { SharedService } from './services/shared.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalLgpdComponent } from './modal-lgpd/modal-lgpd.component';
import { AuthService } from './services/auth.service';
import { AsapViewerService } from 'asap-viewer';
import { filter, pairwise } from 'rxjs/operators';
import { AsapChatModule } from 'asap-chat';
import { ClienteService } from './services/client.service';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxdModule } from '@ngxd/core';
import { TranslateSharedModule } from './translate.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EditorModule } from '@tinymce/tinymce-angular';

declare const console: any;
declare var $: any;

registerLocaleData(localePt);

if (environment.production) {
    window.console.log = () => { };
}

export function asapConfigFactory(http: HttpClient) {
    return () => new Promise<boolean>((resolve) => {
        const configTimer = setInterval(() => {
            if (AsapClientPipe.loaded) {
                clearInterval(configTimer);
                resolve(true);
                console.log('[asapConfigFactory] - ClientPipe Loaded', new Date());
            }
        }, 100);
    });
}

export const MY_FORMATS = {
    parse: {
        dateInput: 'YYYY-MM-DD',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'L',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        HttpClientModule,
        BrowserModule,
        RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
        NgxdModule,
        EditorModule,
        NgCircleProgressModule.forRoot(),
        TranslateSharedModule,
        AsapCrudModule,
        AsapChatModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          // enabled: environment.production,
          // Register the ServiceWorker as soon as the app is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    declarations: [
        AppComponent,
        ModalLgpdComponent
    ],
    entryComponents: [
        ModalLgpdComponent
    ],
    exports: [
      HttpClientModule,
      RouterModule,
      AsapCrudModule,
      AsapChatModule,
      EditorModule
    ],
    providers: [
        HttpClient,
        AuthModule,
        ThemeService,
        PlatformGuardService,
        ClienteService,
        SharedService,
        {
            provide: APP_INITIALIZER,
            useFactory: asapConfigFactory,
            deps: [HttpClient],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ClientInterceptor,
            multi: true
        },
        AuthService,
        { provide: LOCALE_ID, useValue: 'pt-BR' }
    ],
    bootstrap: [AppComponent]
})

export class AppModule {

    constructor(
        private title: Title,
        private router: Router,
        private global: Global,
        private theme: ThemeService,
        private auth: AuthService,
        private viewer: AsapViewerService,
        private client: ClienteService,
    ) {
        console.timeLog('[load]', '[AppModule] Loading...');
        console.log('[AppModule]', Date());
        this.theme.waiting = true;

        setTimeout(() => {
            $('html, body, window, .main-panel').animate({ scrollTop: 0 }, '50');
        }, 10);

        localStorage.setItem('apiURL', environment.apiURL);
        // const event: any = environment.eventPlatform;
        // if (event) {
        //     localStorage.setItem('eventPlatform', event);
        // }
        // else {
            localStorage.removeItem('eventPlatform');
        // }

        const params: any = this.global.decodeQueryString();
        console.log('[AppModule] QueryString', params);
        if (params.url) {
            sessionStorage.setItem('after_login_redirect_url', params.url);
        }
        if (params.foreign) {
            console.log('[AppModule] Logado como Alien...');
            const token = params.foreign;
            localStorage.removeItem('token');
            localStorage.removeItem('loggedUser');
            localStorage.removeItem('plainToken');
            localStorage.setItem('token', `Bearer ${token}`);
            localStorage.setItem('plainToken', token);
            this.auth.getUserLogged().subscribe(res => {
                localStorage.setItem('loggedUser', JSON.stringify(res));
            });
        }

        this.beforeViewerVerify();

        console.log('Theme Host:', window.location.hostname);
        console.log('Route', window.location.pathname);

        let host = window.location.hostname;
        if (host === 'localhost') {
            // host = 'fitnesslink.plataformaasap.com.br';
            // host = 'asta.ecommerce.hmgasap.com.br';
            // host = 'hipconecta.b2c.hmgasap.com.br';
            // host = 'b2c.portal.hmgasap.com.br';
            // host = 'devb2c.b2c.hmgasap.com.br';
            // host = 'marketingportalb2c.b2c.hmgasap.com.br';
            // host = 'curso.b2c.hmgasap.com.br';
             host = 'realiza.b2c.hmgasap.com.br';
            // host = 'jabrasil.portal.hmgasap.com.br';
            // host = 'abeno.b2c.hmgasap.com.br';
            // host = 'sejafinancier.b2c.hmgasap.com.br';
            // host = 'devbot.portal.hmgasap.com.br';
            // host = 'seminariodeimpacto.b2c.hmgasap.com.br';
            // host = 'labfinanceiro.b2c.hmgasap.com.br';
            // host = 'portalimpacthub.b2c.hmgasap.com.br';
            // host = 'impacthubsp.b2c.hmgasap.com.br';
            // host = 'impacthub.b2c.hmgasap.com.br';
            // host = 'ideiagov.b2c.hmgasap.com.br';
            // host = 'ideiagov.impactvc.com.br';
            // host = 'impactamais.b2c.hmgasap.com.br';
            // host = 'www.forum.impactvc.com.br';
            // host = 'eduardocasarotto.consultoriaasap.com.br';
            // host = 'dev.portal.hmgasap.com.br';
            // host = 'forumdeimpacto.b2c.hmgasap.com.br';
            // host = 'conseguro.consultoriaasap.com.br';
            // host = 'treinamentob2c.b2c.hmgasap.com.br';
            // host = 'impactvc.com.br';
            // host = 'personalcross.consultoriaasap.com.br'
            //  host = 'bariatricosclub.b2c.hmgasap.com.br'
            // host = 'dev.portal.hmgasap.com.br'
        }

        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationStart) {
                this.theme.waiting = true;

                setTimeout(() => {
                    $('html, body, window, .main-panel').animate({ scrollTop: 0 }, '50');
                }, 10);

                const url = evt.url.split('?')[0];
                const start = `/${this.theme.mainPage}`;
                console.log('[AppModule] Verificando rota', url);
                if (['/','/school','/portal',start].includes(url)) {
                    if (this.theme.useMainPage()) {
                        if (url != start) {
                            console.log('[AppModule] Redirecionando para o MainPage...', new Date());
                            this.router.navigate([start]);
                            if (window.location.pathname == start) {
                                this.theme.waiting = false;
                            }
                        }
                    }
                    else if (['/','/start'].includes(url)) {
                        if (this.theme.theme.config.template.platforms[0] == 'ecommerce') {
                            console.log('[AppModule] Redirecionando para o E-Commerce...', new Date());
                            this.router.navigate(['/market']);
                            if (window.location.pathname == '/market') {
                                this.theme.waiting = false;
                            }
                        }
                        else if (this.theme.theme.config.template.platforms[0] == 'elearning') {
                            console.log('[AppModule] Redirecionando para o EAD...', new Date(), window.location.pathname);
                            this.router.navigate(['/school']);
                            if (window.location.pathname == '/school') {
                                this.theme.waiting = false;
                            }
                        }
                        else {
                            console.log('[AppModule] Redirecionando para o Portal...', new Date());
                            this.router.navigate(['/portal']);
                            if (window.location.pathname == '/portal') {
                                this.theme.waiting = false;
                            }
                        }
                    }
                }
            }

            if (evt instanceof NavigationCancel) {
                this.theme.waiting = false;
            }

            if (evt instanceof NavigationEnd) {
                this.theme.waiting = false;
                console.log('[AppModule] NavigationEnd', evt.url);
                console.timeLog('[load]', 'NavigationEnd');
            }
        });

        const mentorTimer = setTimeout(() => {
            if (this.global.loggedUser()) {
                clearInterval(mentorTimer);
                const service = new AsapService(this.theme.http);

                service.basePath = 'api/v2/client';
                service.entityName = 'user-mentor/check';

                service.getResources().subscribe((response: any) => {
                    localStorage.setItem('isMentor', (response.status) ? '1' : '0');
                });
            }
        }, 500);

        this.client.getColorByHost(host).subscribe((response) => {
            let dictionary = false;
            const configColor = localStorage.getItem('config_color');
            console.log('Color Theme:', response);
            localStorage.setItem('link_stream', response.data.link_stream);

            if (response.data) {
                dictionary = response.data.config;
                localStorage.setItem('config_color', JSON.stringify(response.data));
            }

            if (dictionary) {
                this.auth.syncActiveClient(response.data.client_id);
                AsapClientPipe.setDictionary(dictionary);
                console.log('[AsapPipe]', AsapClientPipe.dictionary);

                if (AsapClientPipe.dictionary.navbarTransparent == undefined) {
                    AsapClientPipe.dictionary.navbarTransparent = false;
                }

                const html = document.querySelector('html');
                html.style.setProperty('--app-logo-white', AsapClientPipe.dictionary.appLogoWhite);
                html.style.setProperty('--app-logo', `url(${AsapClientPipe.dictionary.appLogoWhite})`);
                html.style.setProperty('--bg-client', AsapClientPipe.dictionary.bgClient);
                html.style.setProperty('--bg-btn', AsapClientPipe.dictionary.primaryColor);
                html.style.setProperty('--primary-color', AsapClientPipe.dictionary.primaryColor);
                html.style.setProperty('--primary-color-hover', AsapClientPipe.dictionary.primaryColorHover);
                html.style.setProperty('--primary-box-shadow', AsapClientPipe.dictionary.primaryBoxShadow);
                html.style.setProperty('--primary-outline-box-shadow', AsapClientPipe.dictionary.primaryOutlineBoxShadow);

                html.style.setProperty('--secondary-color', AsapClientPipe.dictionary.secondaryColor);

                html.style.setProperty('--bg-sidebar-viewer', 'white');
                html.style.setProperty('--link-sidebar-viewer', AsapClientPipe.dictionary.primaryColor);
                html.style.setProperty('--bg-btn-viewer', AsapClientPipe.dictionary.primaryColor);

                html.style.setProperty('--bg-navbar', AsapClientPipe.dictionary.bgNavbar);
                html.style.setProperty('--link-navbar', AsapClientPipe.dictionary.linkNavbar);
                html.style.setProperty('--bg-sidebar', AsapClientPipe.dictionary.bgSidebar);
                html.style.setProperty('--link-sidebar', AsapClientPipe.dictionary.linkSidebar);

                html.style.setProperty('--bg-footer', AsapClientPipe.dictionary.bgFooter);
                html.style.setProperty('--link-footer', AsapClientPipe.dictionary.linkFooter);

                html.style.setProperty('--main-color', AsapClientPipe.dictionary.primaryColor);

                console.log('Firebase initializeApp: ', new Date(), AsapClientPipe.dictionary.firebase);
                localStorage.setItem('firebase_server_id', AsapClientPipe.dictionary.firebase.projectId);
                localStorage.setItem('streamURL', 'https://asta.twcreativs.stream/api/');


                // Adiciona Hubspot script
                if (AsapClientPipe.dictionary.hubspotEnable) {
                    this.addJsToElement('//js.hs-scripts.com/' + AsapClientPipe.dictionary.hubspotPortalId + '.js');
                }

                // Não mexa nas variáveis do visualizador sem a permissão do Natanael
                // Quem mexer é corno!
                html.style.setProperty('--bg-sidebar-viewer', AsapClientPipe.dictionary.bgSidebarViewer);
                html.style.setProperty('--bg-btn-sidebar-viewer-active', AsapClientPipe.dictionary.bgBtnSidebarViewerActive);
                html.style.setProperty('--bg-btn-sidebar-viewer-text-active', AsapClientPipe.dictionary.bgBtnSidebarViewerTextActive);
                html.style.setProperty('--icon-sidebar-viewer', AsapClientPipe.dictionary.iconSidebarViewer);
                html.style.setProperty('--link-sidebar-viewer', AsapClientPipe.dictionary.linkSidebarViewer);

                // console.log('AsapClientPipe.dictionary.appLogoBlack', AsapClientPipe.dictionary.appLogoBlack);
                // this.viewer.options.logoSidebar = AsapClientPipe.dictionary.logoSidebar;
                this.viewer.options.logo = AsapClientPipe.dictionary.logoSidebarViewer || AsapClientPipe.dictionary.logoEAD;
                // console.log('AsapClientPipe.dictionary.appLogoBlack 2', this.viewer.options.logo);

                setTimeout(() => {
                    this.title.setTitle(AsapClientPipe.dictionary.appName);
                    console.log('[themeLoaded]', new Date());
                    $('#favicon').attr('href', AsapClientPipe.dictionary.favicon);
                    this.theme.theme = response.data;
                    this.theme.themeLoaded = true;
                    AsapClientPipe.loaded = true;
                }, 10);
            } else {
                this.router.navigate(['not-found']);
                setTimeout(() => {
                    this.title.setTitle('ASAP - EAD: Cliente não Encontrado!');
                    this.theme.themeLoaded = true;
                }, 10);
            }
        });

    }

    beforeViewerVerify() {
        this.router.events
            .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
            .subscribe((events: RoutesRecognized[]) => {
                const previousUrl = events[0].urlAfterRedirects;
                const currentUrl = events[1].urlAfterRedirects;

                if (
                    currentUrl.includes('/visualizador/') &&
                    !sessionStorage.getItem('urlAfterViewer')
                ) {
                    sessionStorage.setItem('urlAfterViewer', previousUrl);
                }
            });
    }

    addJsToElement(src: string): void {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        // id="hs-script-loader" async defer
        script.id = 'hs-script-loader';
        // this.renderer.appendChild(document.body, script);

        document.body.appendChild(script);
    }
}
