import { Component, Input, OnInit } from '@angular/core'
import { AsapClientPipe, Global } from 'asap-crud';
import { PageService } from 'src/app/services/pages.service';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'pages-navbar',
  templateUrl: './pages-navbar.component.html',
  styleUrls: ['./pages-navbar.component.scss'],
  providers: [ PageService ]
})
export class PagesNavbarComponent implements OnInit {

  @Input() type = 'web';

  public others;
  
  navbarSectionLinks = {
    news: {
      label: 'Notícias',
      link: '/news/view/all'
    },
    trail: {
      label: 'Cursos',
      link: '/training'
    }
  };

  get pageList() {
    let pages: any = localStorage.getItem('config_color');
    if (pages) {
      pages = JSON.parse(pages);
      if (pages && pages.layout && pages.layout.length && pages.layout[0].value && pages.layout[0].value.pages) {
        return pages.layout[0].value.pages;
      }
    }

    return []
  }

  get menuItems() {
    const totalPages = 3;
    if (this.type == 'mobile') {
      let menu = AsapClientPipe.dictionary.navbarMenuItems;
      if (menu.length > totalPages) {
        this.others = menu.slice(totalPages, menu.length);
        return menu.slice(0, totalPages);
      }
    }
    return AsapClientPipe.dictionary.navbarMenuItems;
  }

  constructor(
    public global: Global,
    public pageService: PageService
  ) {

  }

  ngOnInit() {
    
  }

  getPage(page: string) {
    const find = this.pageList.find((item: any) => item.id == page);
    return find || {};
  }

}


