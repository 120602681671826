import { Component, OnInit } from '@angular/core';
import { TrailService } from 'src/app/services/trail.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Global } from 'asap-crud';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { OrderPipe } from 'ngx-order-pipe';
import Swal from 'sweetalert2';
import { AsapJourneyService } from 'asap-journey';

@Component({
    selector: 'app-school-timeline',
    templateUrl: './school-timeline.component.html',
    providers: [TrailService]
})
export class SchoolTimelineComponent {

    public id;
    public trail;
    public modules;
    public error = false;
    public queryParams;
    @BlockUI() blockUI: NgBlockUI;
    jornada_id: any;
    config: any;

    constructor(
        public trailService: TrailService,
        public route: ActivatedRoute,
        public global: Global,
        public router: Router,
        private orderPipe: OrderPipe,
        private asapJourneyService:AsapJourneyService
    ) {
        this.route.queryParams.subscribe(params => {
            this.queryParams = params;

        });

        this.route.params.subscribe((params) => {
            if (this.global.loggedUser()) {
                console.log(params)
                if (params.id) {
                    this.id = params.id;
                    this.jornada_id = this.queryParams.ref;
                    if (this.jornada_id) {
                        this.asapJourneyService.title(this.jornada_id).subscribe((res: any) => {
                            if(res.data[0].metadata) {
                                this.config = JSON.parse(res.data[0].metadata);
                                if(this.config.timeline) {
                                    this.loadTrail();
                                } else {
                                    this.jumpToModule(this.id);
                                }
                            } else {
                                this.loadTrail();
                            }
                     })
                     } else {
                        this.loadTrail();
                    }
                }
            } else {
                // localStorage.setItem('redirect_url', 'school/timeline/' + params.id);
                this.router.navigate(['login']);
            }
        },
            (error: any) => {
                this.error = true;
            });
    }

    public jumpToModule(module_id){
        console.log("timeline:",module_id,this.jornada_id)
        this.trailService.modules(module_id).subscribe((response: any) => {
            const modules = response.data.sort((a, b) => {
                return a.order - b.order;
            });
            if (modules.length > 0) {
                this.modules = modules;
                console.log(modules[0])
                this.router.navigate([`/jornada/${this.jornada_id}/`, modules[0].id, this.toSeoUrl(modules[0].nome)]);
            }
        })
    }

    public toSeoUrl(url) {
        return url.toString()               // Convert to string
            .normalize('NFD')               // Change diacritics
            .replace(/[\u0300-\u036f]/g,'') // Remove illegal characters
            .replace(/\s+/g,'-')            // Change whitespace to dashes
            .toLowerCase()                  // Change to lowercase

            .replace(/&/g,'-and-')          // Replace ampersand
            .replace(/[^a-z0-9\-]/g,'')     // Remove anything that is not a letter, number or dash
            .replace(/-+/g,'-')             // Remove duplicate dashes
            .replace(/^-*/,'')              // Remove starting dashes
            .replace(/-*$/,'');             // Remove trailing dashes
    }

    public loadTrail() {
        this.blockUI.start('Carregando Informações do Curso');
        this.trailService.trail(this.id).subscribe((response: any) => {
            let trail = response.data;

            trail.available = 1;
            if ((trail.price) && (trail.price.ValorTotal > 0)) {
                trail.available = trail.plan_available || trail.trail_available || trail.user_available;
            }

            if (trail.available == 0) {
                Swal.fire({
                    title: "Curso indisponível!",
                    html: "Você ainda não adquiriu este curso!",
                    type: "success",
                    showConfirmButton: true,
                    confirmButtonText: 'Ir para a página de Pagamento!',
                }).then(
                    (result) => {
                        if (result.value) {
                            this.router.navigate(['/payment', this.id]);
                        }
                    }
                )
            }
            else {
                this.trail = trail;
            }

            this.blockUI.stop();
        },
            (error: any) => {
                this.error = true;
                this.blockUI.stop();
            });

        this.trailService.modules(this.id).subscribe((response: any) => {
            const modules = response.data.sort((a, b) => {
                return a.order - b.order;
            });

            if (modules.length > 0) {
                this.modules = modules;
                this.modules = response.data.map((item: any) => {
                    item.subEtapa.data = item.subEtapa.data.sort((d: any, e: any) => {
                        return d.order - e.order;
                    });

                    return item;
                });
            }


            this.blockUI.stop();
        },
            (error: any) => {
                this.error = true;
                this.blockUI.stop();
            });
    }

    goToRoute(itemId) {
        console.log("trail", this.modules);
        // [routerLink]="['/visualizador/etapa',item.id]"
        // if (this.global.loggedUser().client_id==3) {
        //     this.jumpToModule(itemId)
        // } else {
        if (this.global.loggedUser().client_id == 3) {
          if (this.jornada_id) {
            this.router.navigate([`/jornada/${this.jornada_id}/`, itemId, 'etapa-' + itemId]);
          } else {
            const trailName = this.modules.find((m) => m.id == itemId).nome;
            this.router.navigate([`/visualizador/curso/`, itemId + '-' + this.toSeoUrl(trailName)]);
          }
        } else {
            this.router.navigate(['/visualizador/etapa', itemId], { queryParams: this.queryParams });
        }

    }

}
