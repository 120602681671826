import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { ThemeService } from './theme.service';
import { Observable } from 'rxjs';

@Injectable()
export class PlatformGuardService implements CanLoad {

    constructor(
        public theme: ThemeService,
        public router: Router
    ) {
        
    }

    canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
        console.log("[canLoad] Route",route, this.router.url, this.theme.theme);
        return new Promise((resolve) => {
            if (this.theme.theme) {
                if (this.theme.theme.config.template && this.theme.theme.config.template.platforms) {
                    if (this.theme.theme.config.template.platforms.includes(route.data.module)) {
                        resolve(true);
                    }
                    else {
                        console.log("[canLoad] Platform",false);
                        if (this.theme.useMainPage()) {
                            console.log('[PlatformGuardService] Redirecionando para o MainPage...', new Date());
                            this.router.navigate([`/${this.theme.mainPage}`]);
                        }
                        else if (this.theme.theme.config.template.platforms[0] == "ecommerce") {
                            console.log('[PlatformGuardService] Redirecionando para o E-Commerce...', new Date());
                            this.router.navigate(['/market']);
                        }
                        else if (this.theme.theme.config.template.platforms[0] == "elearning") {
                            console.log('[PlatformGuardService] Redirecionando para o EAD...', new Date());
                            this.router.navigate(['/school']);
                        } 
                        else {
                            console.log('[PlatformGuardService] Redirecionando para o Portal...', new Date());
                            this.router.navigate(['/portal']);
                        }
                        resolve(false);
                    }
                }
                else {
                    console.log("[canLoad]",route,true);
                    resolve(true);
                }
            }
        });
    }

}