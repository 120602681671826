import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalLgpdComponent } from '../modal-lgpd/modal-lgpd.component';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html'
})

export class AuthComponent {


  constructor(public dialog: MatDialog) {

  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.loadLGPD();
  }

  loadLGPD() {
    const checkAssign = localStorage.getItem("lgpdAssing");
    if (!checkAssign) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.position = {
        bottom: '0',
        left: '0',
        right: '0'
      }
      this.dialog.open(ModalLgpdComponent, {
        width: '100vw',
        maxWidth: '100vw',
        closeOnNavigation: false,
        disableClose: true,
        backdropClass: 'custom-global-overlay-wrapper',
        hasBackdrop: true,
        position: dialogConfig.position
      });
    }
  }
}
