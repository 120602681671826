import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';

import { OwlModule } from 'ngx-owl-carousel';
import { NgxImgMaxModule } from 'ngx-img-max';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { TermsComponent } from './terms/terms.component';
import { TopMobileComponent } from './top-mobile/top-mobile.component';
import { NgxMaskModule } from 'ngx-mask';

// PORTAL
import { BoxContributorsComponent } from './portal/contributors/box-contributors.component';
import { BoxSpotlightsComponent } from './portal/spotlights/box-spotlights.component';
import { PortalFooterComponent } from './portal/footer/portal-footer.component';

// MARKET
import { MarketFooterComponent } from './market/footer/market-footer.component';
import { AsapImagePipeModule } from 'asap-image-pipe';
import { CurrencyMaskConfig, NgxCurrencyModule } from 'ngx-currency';
import { AsapSkeletonModule } from 'asap-skeleton';
import { AsapCheckoutModule } from 'asap-checkout';

import { MomentModule } from 'ngx-moment';
import { VoucherDataModalLoginComponent } from './navbar/voucher-data-modal-login/voucher-data-modal-login.component';

import { DynamicSidebarModule } from './dynamic-sidebar/dynamic-sidebar.module';
import { PipesModule } from '../@core/pipes/pipes.module';
import { GenericAllComponent } from './generic-all/generic-all.component';
import { NavbarModule } from './navbar/navbar.module';
import { SideMenuModule } from './sidemenu/sidemenu.module';
import { GlobalNotificationModule } from './global-notification/global-notification.module';
import { MessengerNotificationModule } from './messenger-notification/messenger-notification.module';
import { FooterModule } from './footer/footer.module';
import { AsapUploadModule } from 'asap-upload';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { AsapCrudModule } from 'asap-crud';
import { TranslateModule } from '@ngx-translate/core';

export const customCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$',
  suffix: '',
  thousands: '.',
  nullable: true
};

export const maskCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: false,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$',
  suffix: '',
  thousands: '.',
  nullable: false
};

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    OwlModule,
    NgxImgMaxModule,
    InfiniteScrollModule,
    AsapImagePipeModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxMaskModule.forRoot(),
    AsapSkeletonModule,
    AsapCheckoutModule,
    AsapUploadModule,
    AsapCrudModule,
    MomentModule,
    DynamicSidebarModule,
    PipesModule,
    FooterModule,
    NavbarModule,
    SideMenuModule,
    GlobalNotificationModule,
    MessengerNotificationModule,
    TranslateModule
  ],
  declarations: [
    TermsComponent,
    TopMobileComponent,
    BoxContributorsComponent,
    BoxSpotlightsComponent,
    PortalFooterComponent,
    MarketFooterComponent,
    VoucherDataModalLoginComponent,
    GenericAllComponent
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    OwlModule,
    TermsComponent,
    TopMobileComponent,
    NavbarModule,
    SideMenuModule,
    BoxContributorsComponent,
    BoxSpotlightsComponent,
    PortalFooterComponent,
    MarketFooterComponent,
    AsapImagePipeModule,
    AsapCrudModule,
    NgxCurrencyModule,
    MessengerNotificationModule,
    GlobalNotificationModule,
    AsapCheckoutModule,
    FooterModule,
    DynamicSidebarModule,
    PipesModule,
    GenericAllComponent,
    TranslateModule,
    SwiperModule
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  entryComponents: [
    VoucherDataModalLoginComponent
  ]
})

export class SharedModule {
  constructor() {
    console.log('[SharedModule]', new Date());
  }
}
