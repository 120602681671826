import { Component } from '@angular/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html'
})

export class TermsComponent  {
appUseTerm: any;

public title = "Termos de Uso";
public field = "appUseTerm"

}
