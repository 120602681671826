import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AsapCrudModule } from "asap-crud";
import { AsapImagePipeModule } from "asap-image-pipe";
import { PipesModule } from "src/app/@core/pipes/pipes.module";
import { GlobalNotificationModule } from "../global-notification/global-notification.module";
import { MessengerNotificationModule } from "../messenger-notification/messenger-notification.module";
import { PagesNavbarModule } from "../pages-navbar/pages-navbar.module";
import { SideMenuComponent } from "./sidemenu.component";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AsapImagePipeModule,
    PipesModule,
    MessengerNotificationModule,
    GlobalNotificationModule,
    PagesNavbarModule,
    AsapCrudModule,
  ],
  declarations: [
    SideMenuComponent
  ],
  exports: [
    SideMenuComponent
  ],
  entryComponents: [
    
  ]
})

export class SideMenuModule {
  constructor() {
    console.log('[SideMenuModule]', new Date());
  }
}
