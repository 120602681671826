import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { SessionService } from 'src/app/services/session.service';
import { AsapBaseCRUD, AsapService, Global } from 'asap-crud';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { VoucherStore } from 'src/app/@core/store/voucher.store';
import { MatDialog } from '@angular/material/dialog';
import { ModalTermsComponent } from './modal-terms/modal-terms.component';
import { CaptchaService } from 'src/app/services/captcha.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-dynamic-register',
  templateUrl: './dynamic-register.component.html',
  providers: [ CaptchaService ]
})

export class DynamicRegisterComponent extends AsapBaseCRUD implements OnInit {
  @Output() closeSidebarEvent = new EventEmitter();

  profileToRegister: any;
  entity: any;

  public captcha: any;


  constructor(
    public sessionService: SessionService,
    public sharedService: SharedService,
    public global: Global,
    public service: AsapService,
    public auth: AuthService,
    public captchaService: CaptchaService,
    public router: Router,
    public dialog: MatDialog,
    public voucherStore: VoucherStore,
    public activatedRoute: ActivatedRoute
  ) {
    super(global, service);
    this.service.basePath = 'api/v2/client';
    this.service.entityName = 'cad';
    this.editBootstrap = true;
    this.editClass = '';
    this.add();

    this.entity = {
      name: '',
      email: '',
      password: '',
      confirm_password: '',
      checked: false,
      field: null,
      value: null,
      voucher_id: null,
      groups: []
    };

    this.captchaService.getCaptcha().subscribe((response) => {
      this.captcha = response;
      this.entity.code = this.captcha.code;
  });
  }

  ngOnInit() {
    this.getProfileToRegister();
  }

  getProfileToRegister() {
    const profile = this.sessionService.getItemAsObject('profileToRegister');
    if (!profile) {
      this.sharedService.nextData('isLobby');
      return;
    }
    this.profileToRegister = profile;
    this.entity.groups[0] = profile.id;
  }

  save() {
    console.log('save: this.entity', this.entity);
    if (this.entity.password === this.entity.confirm_password) {

      if (this.voucherStore.state.isValid) {
        this.entity.field = this.voucherStore.state.voucher.field;
        this.entity.value = this.voucherStore.state.voucher.value;
        this.entity.voucher_id = this.voucherStore.state.voucher.voucher_id;
      }

      super.save(this.entity,
        (response: any) => {
          this.auth.doLogon(this.entity).then((success) => {
            this.closeSidebarEvent.emit();
          });
        },
        null,
        (error: any) => {
          if (error.success === false) {
            this.global.alert('Erro...', error.message, 'error');
            this.captcha = error.captcha;
            this.entity.code = this.captcha.code;
            this.entity.captcha = null;
          }
          this.router.navigate(['/login']);
        }
      );
    } else {
      this.global.notification.error('Senha...', 'O campo senha e confirmação devem ser iguais!');
    }
  }

  openModalWithTerms() {
    this.dialog.open(ModalTermsComponent, {
        width: '600px',
    });
  }

}

