import { Component,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VoucherStore } from 'src/app/@core/store/voucher.store';
import { SharedService } from 'src/app/services/shared.service';
import { VoucherService } from 'src/app/services/voucher.service';

@Component({
    selector: 'app-dynamic-voucher',
    templateUrl: './dynamic-voucher.component.html',
})

export class DynamicVoucherComponent {

  public voucher: string;
  public errorMessage: string;
  public errorShow: boolean;
  public isPosting: boolean;

  constructor(
    public sharedService: SharedService,
    public voucherService: VoucherService,
    public voucherStore: VoucherStore,
    public router: Router
  ) {

  }

  ngOnInit() {
    const voucher_local = localStorage.getItem('voucher');
    if(voucher_local){
      this.voucher = voucher_local;
      this.isPosting = true;
      this.validate()
    } 
  }

  validate() {
    this.isPosting = true;
    const body = {number: this.voucher};
    this.voucherService.createResource(body)
      .subscribe((res: any) => {
        console.log('validate', res);

        this.isPosting = false;

        if (res.success) {
          this.voucherStore.setVoucher(res.data);
        } else {
          this.errorMessage = res.mensage;
          this.errorShow = true;
        }
      });
  }

}
