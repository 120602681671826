import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-lgpd',
  templateUrl: './modal-lgpd.component.html',
  styleUrls: ['./modal-lgpd.component.scss']
})
export class ModalLgpdComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ModalLgpdComponent>) { }

  ngOnInit() {
  }

  assign() {
    localStorage.setItem("lgpdAssing", "true");
    this.dialogRef.close();
  }

}
