import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AsapImagePipeModule } from "asap-image-pipe";
import { MomentModule } from "ngx-moment";
import { PipesModule } from "src/app/@core/pipes/pipes.module";
import { MessengerNotificationComponent } from "./messenger-notification.component";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AsapImagePipeModule,
    MomentModule,
    PipesModule,
  ],
  declarations: [
    MessengerNotificationComponent,
  ],
  exports: [
    MessengerNotificationComponent
  ],
  entryComponents: [
    
  ]
})

export class MessengerNotificationModule {
  constructor() {
    console.log('[MessengerNotificationModule]', new Date());
  }
}
