import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-modal-terms',
    templateUrl: './modal-terms.component.html',
    styleUrls: ['./modal-terms.component.scss']
})
export class ModalTermsComponent implements OnInit {

    public title = "Termos de Uso";
    public field = "appUseTerm"

    constructor(
        public dialogRef: MatDialogRef<ModalTermsComponent>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
        console.log("[ModalTermsComponent] data", data);
        if (data) {
            if (data.title) {
                this.title  = data.title;
            }
            if (data.field) {
                this.field  = data.field;
            }
        }
    }

    ngOnInit() {
        console.log('Order Data Modal ->', this.data);
    }

    close() {
        this.dialogRef.close();
    }

}
