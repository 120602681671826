import { Component, OnInit } from '@angular/core';
import { PageService } from 'src/app/services/pages.service';
import { ActivatedRoute } from '@angular/router';
import { AsapService } from 'asap-crud';

@Component({
    selector: 'app-static-page',
    templateUrl: './static-page.component.html',
    providers: [PageService]
})
export class StaticPageComponent implements OnInit {

    page: any;
    slug: string;
    loading: boolean;

    constructor(
        public activatedRoute: ActivatedRoute,
        public service: AsapService
    ) {
    }

    ngOnInit() {
        this.activatedRoute.params.subscribe((param: any) => {
            this.slug = param.slug;
            this.getPage();
        });
    }

    getPage() {
        this.loading = true;

        this.service.basePath = 'api/v2/admin';
        this.service.entityName = `static-page`;
        this.service.getResources({query:'search=' + btoa(`slug='${this.slug}'`)}).subscribe(
            (response => {
                this.page = response.data[0];
                this.loading = false;
            }),
            error => {
                this.loading = false;
            })

    }


}
