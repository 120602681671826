import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
            isolate: false
        })
    ],
    exports: [
      TranslateModule
    ],
    providers: [ TranslateService ]
})
export class TranslateSharedModule {

    constructor(translate: TranslateService) {
        const getLanguage = localStorage.getItem('language');                 
        const language = (getLanguage && getLanguage != 'undefined' && getLanguage != '')? localStorage.getItem('language') : 'pt-br';

        console.log('[TranslateSharedModule] Language Detected:', language);
        try { translate.setDefaultLang(language); } catch (error) { console.log('[TranslateSharedModule] Translate Error B:', error); }
        console.log('[TranslateSharedModule] Using Language:', language);
        try { translate.use(language); } catch (error) { console.log('[TranslateSharedModule] Translate Error D:', error); }
        console.log('[TranslateSharedModule] Translate Activated:', language);
    }

 }
