import { Component, OnChanges, SimpleChanges, OnInit, Renderer2, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AsapService, Global } from 'asap-crud';
import { AsapClientPipe } from 'asap-crud';
import { ThemeService } from 'src/app/services/theme.service';
import { CartService } from 'src/app/services/cart.service';
import { AsapMyPageService } from 'asap-my-page';
import { PageService } from 'src/app/services/pages.service';
import { SharedService } from 'src/app/services/shared.service';
import { VoucherDataModalLoginComponent } from './voucher-data-modal-login/voucher-data-modal-login.component';
import { MatDialog } from '@angular/material/dialog';
import { VoucherStore } from 'src/app/@core/store/voucher.store';
import { PanelMentoringService } from 'src/app/services/panel-mentoring.service';
import { multicontaRoute } from 'src/app/school/school.routing';
import { AsapNotificationsComponent } from 'asap-notifications';

declare var $: any;

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [CartService, AsapClientPipe, PanelMentoringService],
})

export class NavbarComponent implements OnInit, OnChanges {

    public static firebase: any;

    @ViewChild('notificationComponent') notificationComponent: AsapNotificationsComponent;

    public dictionary = AsapClientPipe.dictionary;
    public notifications = [];
    public warning;
    public db: any;
    public limitNotifications: any;
    public token: any;
    public asapChat;

    searchInput: string;

    public multicontas;

    public multicontaRoute = multicontaRoute;

    navbarSectionLinks = {
        news: {
            label: 'Notícias',
            link: '/news/view/all'
        },
        trail: {
            label: 'Cursos',
            link: '/training'
        }
    };

    @Output() blockPanel = new EventEmitter<any>();

    mentoriaEnable: boolean;

    panelMentoring: any;
    chatEnable;

    needVoucher = false;
    dataVoucher;
    voucher = "";

    get pageList() {
        return this.global.getOrCreateSessionData('pageList', []);
    }

    constructor(
        public global: Global,
        public router: Router,
        public theme: ThemeService,
        public cartService: CartService,
        public myPageService: AsapMyPageService,
        public pageService: PageService,
        private render: Renderer2,
        public sharedService: SharedService,
        public asapService: AsapService,
        public dialog: MatDialog,
        public clientPipe: AsapClientPipe,
        public voucherStore: VoucherStore,
        public panelMentoringService: PanelMentoringService
    ) {
        this.limitNotifications = 10;
        localStorage.setItem('viewerLogo', this.clientPipe.transform('logoEAD'));
        if (this.global.loggedUser()) {
            this.token = localStorage.getItem('plainToken');
            console.log('token', this.token);
            const interval = setInterval(() => {
                if (NavbarComponent.firebase) {
                    console.log('[Load NavbarComponent Firebase] ->', Date.now());
                    this.db = NavbarComponent.firebase;
                    this.getNotifications();
                    clearInterval(interval);
                } else {
                    // console.log('[Waiting Load NavbarComponent Firebase] ->', Date.now());
                }
            }, 1000);
        }

        $(window).scroll(() => {
            const height = $(window).scrollTop();
            if (height > 300) {
                $('#mainNavbar').removeClass('navbar-transparent');
            } else {
                if (this.router.url === '/school' && this.dictionary.navbarTransparent) {
                    $('#mainNavbar').addClass('navbar-transparent');
                }
            }
        });

        $(() => {
            $('#navbar-search-icon').click(() => {
                console.log('navbar-search-icon clicked');

                $('#navbar-search-form').animate(
                    { width: '100%' }, 500
                );
                $('#navbar-search-form  input[type=search]').focus();
            });
        });

        let multicontasDisplay = AsapClientPipe.dictionary['navbarMulticontasDisplay'];
        let client = localStorage.getItem('client');
        if (multicontasDisplay || ['118', '157', '158', '164', '167'].includes(client)) {
            this.asapService.basePath = 'api/v2/admin';
            this.asapService.entityName = 'asap-client/vinculo/' + client;
            this.asapService.getResources().subscribe((response) => {
                this.multicontas = response.data;
            });
        }

    }

    ngOnInit() {
        this.hasPanelMentoring();

        const checkIfLoginVoucherEnabled = localStorage.getItem('login_voucher_enable');
        if (checkIfLoginVoucherEnabled === '1') {
            const dialogRef = this.dialog.open(VoucherDataModalLoginComponent, {
                width: '500px',
                disableClose: true,
                closeOnNavigation: false,
            });

            dialogRef.afterClosed().subscribe(resultModal => {
                console.log('Result Voucher', resultModal);
                this.asapService.basePath = 'api/v2/client';
                this.asapService.entityName = 'voucher/cad/validate/user';
                this.asapService.createResource(resultModal).subscribe(response => {
                    this.global.toastService.show({
                        title: 'Pronto',
                        message: 'Seu voucher foi salvo com sucesso'
                    });
                    localStorage.removeItem('login_voucher_enable');
                });
            });

        }

        let chatTimer = setInterval(() => {
            let chat = this.global.getSessionData('asap-chat');
            if (chat) {
                console.log("[chatTimer] chat", chat);
                this.asapChat = chat;
                this.hasChat();

                clearInterval(chatTimer);
            }
        }, 500);

        setInterval(() => {
            const mentoriaStorage = localStorage.getItem('isMentor');
            this.mentoriaEnable = (mentoriaStorage === '1');
        }, 1000);

        let voucherTimer = setInterval(() => {
            let user = this.global.loggedUser()
            if (user) {
                clearInterval(voucherTimer);
                if (user.voucher === false) {
                    this.getBanners();
                }
            }
        }, 500);

    }

    getBanners() {
        this.asapService.basePath = 'api/v2/client';
        this.asapService.entityName = 'banner/geral/web';
        this.asapService.getResources({ query: 'type=home' }).subscribe((response) => {
            if (response.data['banner-voucher']) {
                this.needVoucher = true;
                this.dataVoucher = response.data['banner-voucher'];
            }
        });
    }

    openSidebar() {
        const sidebarEl = document.querySelector('.dynamicSidebar');
        const overlayEl = document.querySelector('.dynamicOverlay');

        this.render.removeClass(overlayEl, 'active');
        this.render.addClass(overlayEl, 'active');
        this.render.removeClass(sidebarEl, 'active');
        this.render.addClass(sidebarEl, 'active');

        sessionStorage.removeItem('after_login_redirect_url');

        this.sharedService.nextData('isLogin');
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.changePic) {
            const profpic = this.global.loggedUser();
            console.log('Mudou', profpic.picture);
        }
    }

    public doLogout() {
        this.theme.waiting = true;
        this.global.notification.success('Desconectado', 'Conta desconectada com sucesso');

        // localStorage.removeItem('token');
        // localStorage.removeItem('supplementary_data_check');
        // localStorage.removeItem('loggedUser');
        // localStorage.removeItem('plainToken');
        localStorage.removeItem('token');
        localStorage.removeItem('supplementary_data_check');
        localStorage.removeItem('login_voucher_enable');
        localStorage.removeItem('loggedUser');
        localStorage.removeItem('plainToken');
        localStorage.removeItem('companyToken');
        localStorage.removeItem('permission');
        localStorage.removeItem('isMentor');
        this.voucherStore.reset();

        // this.theme.waiting = false;

        console.log('[doLogout] themew', this.theme.waiting);
        console.log('[doLogout] themeloa', this.theme.themeLoaded);

        this.theme.showHome = false;

        this.cartService.empty();
        setTimeout(() => {
            this.router.navigate(['/']);
            this.theme.showHome = true;
        }, 100);
    }

    public getNotifications() {

        if (this.global.loggedUser()) {

        }
    }

    public redirect(item) {
        console.log(item);
        if (item.redirect) {
            const collection = this.db.collection('notifications').doc(item.id);

            collection.update({ read: true });

            this.router.navigate([item.redirect]);
        }
    }

    public showMoreNotifications() {
        this.limitNotifications = this.limitNotifications + 5;
        console.log('showMoreNotifications', this.limitNotifications);
    }

    public hasPlatform(platform: string): boolean {
        return this.theme.theme.config.template.platforms.includes(platform);
    }

    onSearchSubmit() {
        if (this.router.url === '/portal') {
            this.router.navigate(['/school/search'], { queryParams: { q: this.searchInput } });
        }
        if (this.router.url === '/school') {
            this.router.navigate(['/school/search'], { queryParams: { q: this.searchInput } });
        }
        if (this.router.url === '/universidade') {
            this.router.navigate(['/school/search'], { queryParams: { q: this.searchInput } });
        }
    }

    getPage(page: number) {
        const find = this.pageList.find((item: any) => +item.id === +page);
        return find;
    }

    public dashboardAcess() {
        if (this.global.loggedUser()) {
            let user = this.global.loggedUser();
            if (user.client_id == '125') {
                if (user.info_group[0]) {
                    if (user.info_group[0].name == "Empresa") {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    public unread() {
        let unread = 0;
        // console.log("[unread] chat", this.asapChat);
        if (this.asapChat && this.asapChat.data) {
            this.asapChat.data.forEach(box => {
                box.messages.forEach(m => {
                    if (!m.read) {
                        unread++;
                    }
                });
            });
        }
        return unread;
    }

    public boxState(msg) {
        console.log("[boxState] msg", msg);
        console.log("[boxState] chat", this.asapChat);

        this.asapChat.loadChat(msg._id);
    }

    public hasPanelMentoring() {
        if (this.global.loggedUser()) {
            this.panelMentoringService.getResource(this.global.loggedUser().id).subscribe(response => {
                this.panelMentoring = response.data;
            });
        }

    }

    public hasChat() {
        this.chatEnable = false;
        if (this.hasPermissions('chat', 'comunicacao')) {
            console.log("[hasChat] chatEnable if", this.chatEnable);
            this.chatEnable = true;
        }

    }

    public hasPermissions(permission_tag, parent = null) {

        let permission: any = localStorage.getItem('permissionClient');

        if (permission) {
            permission = JSON.parse(permission);
            let permissions = permission.resource_sub;
            if (parent) {
                let permission: any = permissions.find(item => item.tag == permission_tag && item.parent == parent);
                let ovo = true;
                console.log("permission 1", ovo);
                return (!permission) ? false : permission.enabled;
            }
            else {
                let permission: any = permissions.find(item => item.tag == permission_tag);
                console.log("permission 2", permission);
                return (!permission) ? false : permission.enabled;
            }
        }
        console.log("permission 3", permission);
        return false;


    }

    useVoucher() {
        if (this.voucher == '') {
            this.global.toastService.warning({
                message: "Preencha o Voucher corretamente...",
                position: "topCenter"
            });
        }
        else {
            let user_id = this.global.loggedUser().id;
            let voucher = this.voucher;
            this.voucher = '';
            this.asapService.basePath = 'api/v21/client';
            this.asapService.entityName = 'voucher/cad/validate';
            this.asapService.createResource({ number: voucher }, { query: `user=${user_id}` }).subscribe((response) => {
                if (response.success) {
                    this.global.toastService.info({
                        message: "Voucher válido! Bem vindo...",
                        position: "topCenter"
                    });
                    this.theme.showHome = false;
                    this.needVoucher = false;
                    setTimeout(() => {
                        this.theme.showHome = true;
                    }, 100);
                }
                else {
                    this.global.toastService.error({
                        title: 'Erro',
                        message: response.mensage,
                        position: "topCenter"
                    });
                }
            });
        }
    }

    public unreadNotifications(event) {
        console.log("[unreadNotifications]", event);
        this.notifications = [
            ... this.notifications,
            ...event.data
        ];
    }

    public watchNotifications(event) {
        console.log("[watchNotifications]", event);
        const idx = this.notifications.findIndex(n => n._id == event.data._id);
        if (event.action == 'insert') {
            this.notifications.push(event.data);
            this.global.toastService.show({
                title: event.data.title,
                message: event.data.content,
                maxWidth: '300px',
                image: event.data.icon,
                balloon: true,
                position: 'bottomRight',
                titleSize: '14',
                messageSize: '15',
                timeout: 4000,
                transitionOut: 'flipOutX',
                transitionIn: 'flipInX',
                imageWidth: 85,
                toastOnce: true,
            });

        }
        else if (event.action == 'update') {
            this.notifications[idx] = event.data;
        }
        else if (event.action == 'delete') {
            this.notifications.splice(idx, 1);
        }
    }

    public unreadWarning(event) {
        console.log("[unreadWarning]", event);
        this.warning = event.data[0];
    }

    public watchWarning(event) {
        console.log("[watchWarning]", event);
        if (event.action == 'insert') {
            this.warning = event.data;
            
        }
        else if (event.action == 'update') {
            const isActual = this.warning._id == event.data._id;
            if (isActual) {
                this.warning = event.data;
            }
        }
        else if (event.action == 'delete') {
            const isActual = this.warning._id == event.data._id;
            if (isActual) {
                this.warning = null;
            }
        }
    }

    public readWarning() {
        this.notificationComponent.readWarning(this.warning).subscribe((response) => {

        });
    }

    public readNotification(item) {
        this.notificationComponent.read(item).subscribe(response => {
            if (item.origin == 'courses' || item.origin == 'courses_session') {
                this.router.navigate(['/training', item.aditional_data.courseID]);
            }

            if (item.origin == 'channel_file') {
                this.router.navigate([`/channel/files/${item.aditional_data.channelID}/${item.aditional_data.fileID}`]);
            }

            if (item.origin == 'channel_video') {
                this.router.navigate([`/watch/${item.aditional_data.videoID}`]);
            }
        })
    }

    public becomeNotified() {
        this.notifications.filter(n => !n.notified).forEach(n => {
            this.notificationComponent.service.notified(n).subscribe(() => { });
        });
    }

}
