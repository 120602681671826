import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AuthRoutes } from './auth.routing';
import { AuthComponent } from './auth.component';
import { PipesModule } from '../@core/pipes/pipes.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(AuthRoutes),
        PipesModule,
        SharedModule
    ],
    declarations: [
        AuthComponent,
    ],
    providers: [

    ],
    exports: [
        AuthComponent,
    ],
    entryComponents: [

    ],
    bootstrap: []
})
export class AuthModule { }
