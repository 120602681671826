import { Component, OnInit, OnDestroy } from '@angular/core';
import { Global } from 'asap-crud';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { BrowserNotificationsService, PushNotification } from 'src/app/services/browser-notification.service';
import { AsapClientPipe } from 'asap-crud';

@Component({
    selector: 'app-global-notification',
    templateUrl: './global-notification.component.html',
    styleUrls: ['./global-notification.component.scss'],
    providers: [ BrowserNotificationsService, AngularFirestore ]
})
export class GlobalNotificationComponent implements OnInit, OnDestroy {

    public dictionary = AsapClientPipe.dictionary;

    public keepAlive;

    public users: any=[];
    public usersNotifications: any;
    notifications: any[];
    limitNotifications: any;
  totalNotifications: number;

    constructor(
        public global: Global,
        private router: Router,
        public firestore: AngularFirestore,
        public notification: BrowserNotificationsService
    ) {
        if (this.global.loggedUser()) {
            const collection = this.firestore.collection('notifications', (ref: any) => {
                const query = ref.orderBy('viewed', 'asc').orderBy('create_at', 'desc')
                  .where('receiver_id', '==', this.global.loggedUser().id)
                  // .where('read', 'in', [true,false])
                return query;
              });
        
              collection.snapshotChanges().subscribe((notificationDoc) => {
                console.log('Changes Notification ->', notificationDoc);
                this.notifications = [];
                notificationDoc.map(data => {
                  const notificationData: any = data.payload.doc.data();
                  notificationData.id = data.payload.doc.id;
                  notificationData.resource = data.type;
                  this.notifications.push(notificationData);
                });
        
                console.log('Notification Snapshot', this.notifications);

                const collectionTotal = this.firestore.collection("notifications", (res) =>
                res.orderBy("viewed", "asc")
                  .orderBy("create_at", "desc")
                  .where("receiver_id", "==", this.global.loggedUser().id)
                  .where('read', '==', false)
              );
          
              collectionTotal.valueChanges().subscribe(res => {
                this.totalNotifications = res.length;
              });
        
        
                if (this.notifications && this.notifications.length > 0) {
                  if (this.notifications[0].resource === 'added' && !this.notifications[0].read) {
                    const notification = this.notifications[0];
        
                    if (notification.type == 'feed') {
                      this.global.toastService.show({
                        position: 'bottomRight',
                        image: notification.user.picture,
                        title: notification.title,
                        message: notification.description,
                        titleSize: '14',
                        messageSize: '15',
                        timeout: 12000,
                        transitionOut: 'flipOutX',
                        transitionIn: 'flipInX',
                        imageWidth: 85,
                        maxWidth: '380',
                        toastOnce: true,
                        id: notification.id,
                      });
                    } else {
                      this.global.toastService.show({
                        position: 'bottomRight',
                        title: notification.title,
                        message: notification.description,
                        titleSize: '14',
                        messageSize: '15',
                        timeout: 12000,
                        transitionOut: 'flipOutX',
                        transitionIn: 'flipInX',
                        maxWidth: '300'
                      });
                    }
        
                    // this.db.collection('notifications').doc(this.notifications[0].id).update({ viewed: true });
                  }
                }
              });
        }
    }
    ngOnInit() {
        this.limitNotifications = 10;

    }

    
    public redirect(item) {
        const collection = this.firestore.collection('notifications').doc(item.id);
        collection.update({ read: true });
        
        if (item.redirect) {
            if(item.redirect.indexOf('order')!=-1) {
                this.router.navigate(['my-purchases']);
            } else {
                this.router.navigate([item.redirect]);
            }
        }
      }

    public showMoreNotifications() {
        this.limitNotifications = this.limitNotifications + 5;
        console.log('showMoreNotifications', this.limitNotifications);
    }

    ngOnDestroy() {
      
    }

}
