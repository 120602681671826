import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';

import { DynamicSidebarComponent } from './dynamic-sidebar.component';
import { DynamicLoginComponent } from './login/dynamic-login.component';
import { DynamicRegisterComponent } from './register/dynamic-register.component';
import { DynamicLobbyComponent } from './lobby/dynamic-lobby.component';
import { DynamicRecoveryComponent } from './recovery/dynamic-recovery.component';
import { PipesModule } from 'src/app/@core/pipes/pipes.module';
import { DynamicAddressComponent } from './address/dynamic-address.component';
import { DynamicCoursesListComponent } from './courses-list/dynamic-courses-list.component';
import { DynamicVoucherComponent } from './voucher/dynamic-voucher.component';
import { ModalTermsComponent } from './register/modal-terms/modal-terms.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    MaterialModule,
    PipesModule
  ],
  declarations: [
    DynamicSidebarComponent,
    DynamicLoginComponent,
    DynamicRegisterComponent,
    DynamicLobbyComponent,
    DynamicRecoveryComponent,
    DynamicAddressComponent,
    DynamicCoursesListComponent,
    DynamicVoucherComponent,
    ModalTermsComponent
  ],
  exports: [
    DynamicSidebarComponent,
    DynamicLoginComponent,
    DynamicRegisterComponent,
    DynamicLobbyComponent,
    DynamicRecoveryComponent,
    DynamicAddressComponent,
    DynamicCoursesListComponent,
    DynamicVoucherComponent,
    ModalTermsComponent
  ],
  providers: [

  ],
  entryComponents: [
    ModalTermsComponent
  ]
})
export class DynamicSidebarModule {

  constructor() {
    console.log('[DynamicSidebarModule]', new Date());
  }

}
