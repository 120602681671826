import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class SessionService {

  setItem(key: string, data: any) {
    if (typeof data === 'object') {
      sessionStorage.setItem(key, JSON.stringify(data));
    } else {
      sessionStorage.setItem(key, String(data));
    }
  }

  getItem(key: string): any {
    return sessionStorage.getItem(key);
  }

  getItemAsObject(key: string) {
    const session = sessionStorage.getItem(key);
    if (session) {
      return JSON.parse(session);
    }
    return false;
  }

}
