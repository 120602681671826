import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Global } from 'asap-crud';
import { Router } from '@angular/router';

@Injectable({providedIn: 'root'})
export class VoucherService extends BaseService {

  constructor(
    public http: HttpClient,
    public global: Global,
    public router: Router
  ) {
    // post https://api.tecnologiaasap.com.br/api/v21/client/voucher/cad/validate?client_id=108
    super(http, 'api/v21/client', 'voucher/cad/validate');
  }

}
