import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-generic-all',
  templateUrl: './generic-all.component.html',
  styleUrls: ['./generic-all.component.scss'],
})
export class GenericAllComponent implements OnChanges {
  @Input() data: Array<any>;
  @Input() type: string;

  dataFilter: any;

  searchInput: FormControl;

  constructor(
    public router: Router
  ) {
    this.searchInput = new FormControl();
    this.searchInput.valueChanges.subscribe(value => {
      this.filterData(value);
    });
  }

  ngOnChanges(changes: any) {
    if (changes.data) {
      this.dataFilter = this.data;
    }
  }

  filterData(filter: string): void {
    this.dataFilter = this.data.filter((item: any) => item.title.includes(filter));
  }

  navigateToItem(item: any) {
    this.router.navigate(['/news', item.id]);
  }
}
