import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from 'asap-crud';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
    selector: 'app-navbar-mobile',
    templateUrl: './navbar-mobile.component.html'
})

export class NavbarMobileComponent {

  public client_id: any;
  public service: any;

  get pageList() {
    return this.global.getOrCreateSessionData('pageList', []);
  }

  constructor(
    public global: Global,
    public router: Router,
    public theme: ThemeService
  ) {

  }

  public doLogout() {
    this.global.notification.success('Desconectado', 'Conta desconectada com sucesso');
      localStorage.clear();
      location.href = '/portal';
  }
  
  public hasPlatform(platform: string) {
    return this.theme.theme.config.template.platforms.includes(platform);
  }
  
}
