import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Global } from 'asap-crud';
import { Router } from '@angular/router';
import { AsapClientPipe } from 'asap-crud';
import { SelectControlValueAccessor } from '@angular/forms';

@Injectable()
export class AuthService extends BaseService {

  constructor(
    public http: HttpClient,
    public global: Global,
    public router: Router,
  ) {
    super(http, 'api', '');
  }

  public login(user) {
    return this.createResource(user, { router: 'oauth/access_token', query: 'client_id=' + this.global.clientID() });
  }

  public register(user, clientID) {
    const body = {
      client_id: clientID,
      user
    };
    return this.createResource(body, { router: 'v2/client/register' });
  }

  public isAuthenticated(): boolean {
    const token: string = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    return true;
  }

  public setSessionToken(data): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const token = 'Bearer' + ' ' + data.token;
      localStorage.setItem('token', token);
      localStorage.setItem('plainToken', data.token);
      setTimeout(() => {
        this.getUserLogged().subscribe((response: any) => {
          this.verifica().subscribe((verifica: any) => {

            localStorage.setItem('supplementary_data_check', (verifica.success) ? '1' : '0');
            localStorage.setItem('login_voucher_enable', (response.data.login_voucher_enable) ? '1' : '0');

            if (response.success === false) {
              resolve(false);
            } else {
              localStorage.setItem('loggedUser', JSON.stringify(response));
              this.verifyMentoria(response.data.id);
              this.verifyChat();
              resolve(true);
            }
          });
        }, err => reject(err));
      }, 100);
    });
  }

  public socialAuth(body) {
    return this.createResource(body, { router: 'social_auth/grupo' });
  }

  public verifica() {
    return this.getResources({ router: 'v2/client/verifica' });
  }

  public complement() {
    return this.getResources({ router: 'v2/admin/user/form' });
  }

  public complementSave(id: number, data: any) {
    return this.updateResource(data, { router: 'v2/perfil/sobre/' + id });
  }

  public getUserLogged() {
    return this.getResources({ router: 'v2/client/user/authenticated' });
  }

  public userInfo(id) {
    return this.getResources({ router: 'v2/client/feed/userInfo' });
  }

  verifyMentoria(id) {
    this.getResources({router:'v2/client/user-mentor/check'}).subscribe((res: any) => {
        localStorage.setItem('isMentor', (res.status) ? '1' : '0');
    });
}

  public verifyChat() {
    this.getResources({router:'v2/admin/asap-user/client/permission'}).subscribe((res: any) => {
      localStorage.setItem('permissionClient',JSON.stringify(res.data));
    });
  }

  public verifyClient(clientID: number) {
    return this.createResource({ client_id: clientID }, { router: 'v2/cliente/verifyClient' });
  }

  public dismissStorage(): void {
    localStorage.clear();
  }

  public syncActiveClient(client: string): void {
    localStorage.setItem('client', client);
  }

  public getActiveClient(): any {
    return +localStorage.getItem('client');
  }

  public resetPassword(body): any {
    return this.createResource(body, { router: 'v2/client/reset' });
  }


  public doLogon(user, redirect = true) {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.login(user).subscribe(
          (data) => {
            this.setSessionToken(data).then(res => {
              if (res) {
                const orderExists = JSON.parse(localStorage.getItem('order'));
                const orderID = localStorage.getItem('oderID');
                const checkoutRoute = localStorage.getItem('checkout-route');
                const redirectUrl = localStorage.getItem('redirect_url');
                let   afterLoginRedirectUrl = sessionStorage.getItem('after_login_redirect_url');

                this.global.notification.success('Login', 'Autenticação feita com sucesso!');

                localStorage.removeItem('voucher');
                if (orderExists && orderID) {
                  console.log('AuthService: orderExists && orderID');
                  this.router.navigate([checkoutRoute]);
                } else if (redirectUrl && redirectUrl.length > 0) {
                  console.log('AuthService: redirectUrl && redirectUrl.length > 0');
                  this.router.navigate([redirectUrl]);
                  localStorage.removeItem('redirect_url');
                } else if (afterLoginRedirectUrl) {
                  afterLoginRedirectUrl = decodeURIComponent(afterLoginRedirectUrl);
                  let url = afterLoginRedirectUrl.split('?');
                  let params = this.decodeQueryString(url[1]);
                  console.log('URLSearchParams',params);
                  this.router.navigate([url[0]],{queryParams: params});
                  sessionStorage.removeItem('after_login_redirect_url');
                } else {
                  console.log('AuthService: else');
                  if (redirect) {
                    this.redirect();
                  }
                }

                resolve(true);

              } else {
                this.router.navigate(['/erros/403']);
              }
            })
              .catch(err => {
                console.log(err);
                if (resolve) { resolve(false); }
              });
          },
          err => {
            this.global.notification.error('Login', 'Nome de usuário ou senha incorretos.');
            console.log(err);
            if (resolve) { resolve(false); }
          }
        );
      }, 100);
    });
  }

  public decodeQueryString(query) {
    console.log("query",query);
    let result = {};
    if (query) {
      let queries = query.split("&");
      for (let q of queries) {
        let key = q.split("=");
        result[key[0]] = key[1]
      }
    }
    return result;
  }

  public redirect() {
      const sdRequired = AsapClientPipe.dictionary.supplementaryDataRequired;

      const check = localStorage.getItem('supplementary_data_check');
      const lu = localStorage.getItem('loggedUser');

      const route = (lu && sdRequired && check === '0') ? '/dados-complementares' : '/portal';

      console.log('AuthService: route', route);
      if (this.global.loggedUser()) {
        const user = this.global.loggedUser();
        //não apagar por favor
        // if ((user.client_id === '125') && (check === '1') ) {
        //   if (user.info_group[0]) {
        //     if (user.info_group[0].name === 'Empresa') {
        //       const token = localStorage.getItem('plainToken');
        //       const url = `https://dashboard.realiza.vc/?token=${token}`;
        //       window.location.href = url;
        //     }
        //   }
        // }
      }
      this.router.navigate([route]);
  }

}
