import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Global } from 'asap-crud';
import { Router } from '@angular/router';

@Injectable()
export class ProductService extends BaseService {

    constructor(
        public http: HttpClient,
        public global: Global,
        public router: Router
    ) {
        super(http,'api/v2','')
    }

    public product(id) {
        return this.getResources({router:'client/produto/' + id});
    }

    public categories() {
        return this.getResources({router:'client/categoria/' + this.global.clientID()});
    }

    public subCategories(category) {
        return this.getResources({router:'client/subcategoria/' + this.global.clientID() + "/" + category});
    }

    public subCategoryProducts(id,page=null) {
        let qryPage = null;
        if (page) {
            qryPage = "page=" + page;
        }
        return this.getResources({router:'client/showcasefilter/' + id, query: qryPage});
    }

    public mainProducts() {
        return this.getResources({router:'client/produto/mais/vendidos/' + this.global.clientID()});
    }

    public spotlightProducts(page = null, search = '') {
        let qryPage = 'pagination=true';
        if (page) {
            qryPage += '&page=' + page;
        }
        if (search !== '') {
            qryPage += '&busca=' + search;
        }
        return this.getResources({router: 'client/showcase/' + this.global.clientID(), query: qryPage});
    }

    public order(id,orderData) {
        return this.createResource(orderData,{router:'loja/pedido/' + id});
    }

    public searchCep(cep) {
        return this.http.get('https://viacep.com.br/ws/'+cep+'/json/');
    }

    public checkUserExists(email){
        return this.getResources({router:'admin/verify/user?search='+email + '&client_id=' +this.global.clientID()});
    }


}
