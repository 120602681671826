import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({ name: 'stream' })
export class StreamPipe implements PipeTransform {

  public url: string;

  constructor(private domSanitizer: DomSanitizer) {
    let session;
    if (localStorage.getItem('link_stream')) {
      session = localStorage.getItem('link_stream');
      this.url = session;
    }
    else if (JSON.parse(localStorage.getItem('loggedUser')).data) {
      session = JSON.parse(localStorage.getItem('loggedUser')).data;
      this.url = (session.link_company) ? session.link_company : session.link;
    }

    console.log('stream ->', this.url);
  }

  transform(video: string, arg?: string): SafeResourceUrl | string {
    if (arg === 'link') {
      return this.getVideoUri(video);
    }
    if (arg === 'thumb') {
      return this.getVideoThumb(video);
    }
    if (arg === 'id') {
      return this.getVideoId(video);
    }
    if (arg === 'poster') {
      return this.url + 'videos/thumb/' + this.getVideoId(video)
    }
    return video;
  }

  getVideoThumb(url: string): SafeResourceUrl {
    if (this.isUrl(url)) return url;
    return this.domSanitizer.bypassSecurityTrustResourceUrl(this.url + 'videos/thumb/' + this.getVideoId(url));
  }

  getVideoUri(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(this.url + 'videos/' + this.getVideoId(url) + '/480');
  }

  getVideoId(url: string): string {
    if (this.isUrl(url)) {
      const split = url.split('/');
      return split[4];
    }
    return url;
  }

  isUrl(s: string): boolean {
    const regexp = /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    return regexp.test(s);
  }

  isOldServer(url) {
    if (url.indexOf('api-dev.sr02.twcreativs.stream') >= 0) return true;
    return false;
  }


}
