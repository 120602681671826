import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from 'asap-crud';
import { AuthService } from 'src/app/services/auth.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
    selector: 'app-dynamic-recovery',
    templateUrl: './dynamic-recovery.component.html',
})

export class DynamicRecoveryComponent {

    email: string;

    constructor(
        public sharedService: SharedService,
        public global: Global,
        public auth: AuthService,
        public router: Router
    ) {

    }

    public recovery() {
        if (this.email === '') {
            this.global.notification.error('Erro!', 'Informe o seu e-mail de Cadastro!');
        } else {
            this.auth.resetPassword({ email: this.email }).subscribe(
                (response: any) => {
                    if (response.success) {
                        this.global.notification.success('Sucesso!', response.mensagem);
                        this.router.navigate(['/login']);
                    } else {
                        this.global.notification.error('Erro!', 'Ocorreu algum erro, confira seu e-mail de Cadastro e tente novamente');
                    }
                },
                (err: any) => {
                    this.global.notification.error('Erro!', 'Ocorreu algum erro, confira seu e-mail de Cadastro e tente novamente');
                }
            );
        }
    }


}
