import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { AsapImagePipeModule } from "asap-image-pipe";
import { AsapSkeletonModule } from "asap-skeleton";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { MomentModule } from "ngx-moment";
import { PipesModule } from "src/app/@core/pipes/pipes.module";
import { FirebaseModule } from "src/app/firebase.module";
import { GlobalNotificationComponent } from "./global-notification.component";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    InfiniteScrollModule,
    MomentModule,
    AsapImagePipeModule,
    AsapSkeletonModule,
    PipesModule
  ],
  declarations: [
    GlobalNotificationComponent,
  ],
  exports: [
    GlobalNotificationComponent
  ],
  entryComponents: [
    
  ]
})

export class GlobalNotificationModule {
  constructor() {
    console.log('[GlobalNotificationModule]', new Date());
  }
}
