import { Global } from 'asap-crud';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { PageService } from 'src/app/services/pages.service';
import { AsapClientPipe } from 'asap-crud';
import { window } from 'rxjs/operators';
import { ModalTermsComponent } from '../dynamic-sidebar/register/modal-terms/modal-terms.component';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: [ './footer.component.scss' ],
    providers: [PageService]
})
export class FooterComponent implements OnInit {
    public ano = moment().format('YYYY');
    public layout: any;
    public menuArray: any = [];
    pageList: any;
    public dictionary = AsapClientPipe.dictionary;

    public constructor(
        public global: Global,
        public pageService: PageService
    ) {

        // this.menuArray.push({
        //   link: '/training',
        //   type: 'slides',
        //   text: '',
        //   translate: true,
        //   fixed: false,
        // });

        // this.menuArray.push({
        //   link: '/journey',
        //   type: 'journey',
        //   text: '',
        //   translate: true,
        //   fixed: false,
        // });
        // this.menuArray.push({
        //   link: '/development',
        //   type: 'mini',
        //   text: '',
        //   translate: true,
        //   fixed: false,
        // });
        // this.menuArray.push({
        //   link: '/influencers',
        //   type: 'influenciadores',
        //   text: '',
        //   translate: true,
        //   fixed: false,
        // });
        // this.menuArray.push({
        //   link: '/videos',
        //   type: 'videos',
        //   text: '',
        //   translate: true,
        //   fixed: false,
        // });
        // this.menuArray.push({
        //   link: '/articles',
        //   type: 'artigos',
        //   text: 'Artigos',
        //   translate: true,
        //   fixed: false,
        // });
        // this.menuArray.push({
        //   link: '/technical',
        //   type: '',
        //   text: 'Área técnica',
        //   translate: true,
        //   fixed: false,
        // });
        // if (global.loggedUser()) {
        //   this.menuArray.push({
        //     link: '/my-page',
        //     type: '',
        //     text: 'Minha página',
        //     translate: true,
        //     fixed: true,
        //   });
        // }
        // this.menuArray.push({
        //   link: '/forum',
        //   type: '',
        //   text: 'Forum',
        //   translate: true,
        //   fixed: false,
        // });
        // this.menuArray.push({
        //   link: '/helpdesk',
        //   type: '',
        //   text: 'HelpDesk',
        //   translate: true,
        //   fixed: false,
        // });
        // this.menuArray.push({
        //   link: '/eventos',
        //   type: 'eventos',
        //   text: '',
        //   translate: true,
        //   fixed: false,
        // });
        // this.menuArray.push({
        //   link: '/eventos',
        //   type: 'evento-sessao',
        //   text: '',
        //   translate: true,
        //   fixed: false,
        // });
        this.menuArray.push({
            link: '/videos',
            type: 'videos-sessao',
            text: this.findText('videos-sessao', ''),
            translate: true,
            fixed: false,
        });
        // if (global.loggedUser()) {
        //   if (global.loggedUser().client_id!=3) {
        //     this.menuArray.push({
        //       link: '/training',
        //       type: 'cursos-sessao',
        //       text: '',
        //       translate: true,
        //       fixed: false,
        //     });
        //   }
        // }

        // this.menuArray.push({
        //   link: '/all-news',
        //   type: 'news-section',
        //   text: 'Notícias',
        //   translate: true,
        //   fixed: false,
        // });

        if (this.global.loggedUser()) {
            this.menuArray.push({
                link: '/my-page',
                type: '',
                text: 'Meu Perfil',
                translate: true,
                fixed: true,
            });

            // if (this.global.loggedUser().client_id != 3) {
            //     if (element.type == 'cursos-sessao') {
            //         this.menuArray.push({
            //             link: '/home/all/courses',
            //             type: 'cursos-sessao',
            //             text: element.title.text,
            //             translate: true,
            //             fixed: false,
            //             session_id: element.session_id
            //         });
            //     }
            // }

            if (this.global.loggedUser().client_id == 125) {
                this.menuArray.push({
                    link: '/curriculum',
                    type: '',
                    text: 'Meu currículo',
                    translate: true,
                    fixed: true,
                });
            }


            if (this.dictionary.navbarHelpdeskDisplay) {
                this.menuArray.push({
                    link: '/helpdesk',
                    type: '',
                    text: 'Suporte',
                    translate: true,
                    fixed: this.dictionary.navbarHelpdeskDisplay,
                });
            }

            if (this.dictionary.navbarFAQDisplay) {
                this.menuArray.push({
                    link: '/faq',
                    type: '',
                    text: 'FAQ',
                    translate: true,
                    fixed: this.dictionary.navbarFAQDisplay,
                });
            }
        }
    }

    ngOnInit() {
        this.getPages();


        const interval = setInterval(() => {
            if (localStorage.getItem('layout_home')) {

                const layout = localStorage.getItem('layout_home');
                if (layout) {
                    this.layout = JSON.parse(layout);
                }


                this.layout.main.forEach(element => {


                    if (element.type == 'cursos-sessao') {
                        this.menuArray.push({
                            link: '/home/all/courses',
                            type: 'cursos-sessao',
                            text: element.title.text,
                            translate: true,
                            fixed: false,
                            session_id: element.session_id
                        });
                    }
                    if (element.type == 'jobs') {
                        this.menuArray.push({
                            link: '/vacancies',
                            type: 'jobs',
                            text: 'Vagas',
                            translate: true,
                            fixed: false
                        });
                    }

                    if (element.type == 'slides') {
                        this.menuArray.push({
                            link: '/training',
                            type: 'slides',
                            text: '',
                            translate: true,
                            fixed: false,
                        });
                    }

                    /*  if (element.type == 'journey') {
                         this.menuArray.push({
                             link: '/journey',
                             type: 'journey',
                             text: this.findText(element.type, ''),
                             translate: true,
                             fixed: false,
                         });
                     } */

                    if (element.type == 'mini') {
                        this.menuArray.push({
                            link: '/development',
                            type: 'mini',
                            text: this.findText(element.type, ''),
                            translate: true,
                            fixed: false,
                        });
                    }


                    if (element.type == 'influenciadores') {
                        this.menuArray.push({
                            link: '/influencers',
                            type: 'influenciadores',
                            text: this.findText(element.type, ''),
                            translate: true,
                            fixed: false,
                        });
                    }


                    if (element.type == 'videos') {
                        this.menuArray.push({
                            link: '/videos',
                            type: 'videos',
                            text: this.findText(element.type, ''),
                            translate: true,
                            fixed: false,
                        });
                    }


                    if (element.type == 'artigos') {
                        this.menuArray.push({
                            link: '/articles',
                            type: 'artigos',
                            text: 'Artigos',
                            translate: true,
                            fixed: false,
                        });
                    }


                    if (element.type == 'main-tecnico') {
                        this.menuArray.push({
                            link: '/technical',
                            type: 'main-tecnico',
                            text: 'Documentos',
                            translate: true,
                            fixed: false,
                        });
                    }

                    if (element.type == 'forum') {
                        this.menuArray.push({
                            link: '/forum',
                            type: '',
                            text: 'Forum',
                            translate: true,
                            fixed: false,
                        });
                    }

                    if (element.type == 'news-section') {
                        this.menuArray.push({
                            link: '/news/view/all',
                            type: 'news-section',
                            text: 'Notícias',
                            translate: true,
                            fixed: false,
                        });
                    }
                    if (element.type == 'eventos') {
                        this.menuArray.push({
                            link: '/home/all/events?session_name=EVENTOS',
                            type: 'eventos',
                            text: this.findText(element.type, ''),
                            translate: true,
                            fixed: false,
                        });
                    }

                    if (element.type == 'evento-sessao') {
                        this.menuArray.push({
                            link: '/home/all/events?session_name=EVENTOS',
                            type: 'evento-sessao',
                            text: element.title.text,
                            translate: true,
                            fixed: false,
                        });
                    }
                    // if (element.type == 'videos-sessao') {
                    //     this.menuArray.push({
                    //         link: '/videos',
                    //         type: 'videos-sessao',
                    //         text: element.title.text,
                    //         translate: true,
                    //         fixed: false,
                    //     });
                    // }

                });

                this.menuArray = this.menuArray.map((item: any) => {
                    if (item) {
                        if (item.fixed === false) {
                            if (this.findComponent(item.type)) {
                                item.text = item.text;
                            }
                            else {
                                item.text = false;

                            }
                        }

                        console.log("item 1", item);
                        return item;
                    }
                });

                this.menuArray = this.menuArray.filter((item: any) => item.fixed !== false || item.text !== false);
                console.log("menu", this.menuArray);

                clearInterval(interval);

            } else {
                console.log('[Waiting Load FooterComponent] ->', Date.now());
            }
        }, 1000);


    }

    public findText(type, text) {
        if (!this.layout) {
            const layout = localStorage.getItem('layout_home');
            if (layout) {
                this.layout = JSON.parse(layout);
            }
        }
        if (this.layout) {
            const menu = this.layout.main.find((item: any) => type === item.type);
            if (menu && menu.title && menu.title.text) {
                const textMenu = menu.title.text.toLowerCase();
                return textMenu.charAt(0).toUpperCase() + textMenu.slice(1);
            } else {
                return false;
            }
        }
        console.log('text', text);
        return text;
    }

    public findComponent(type: string) {
        if (!this.layout) {
            const layout = localStorage.getItem('layout_home');
            if (layout) {
                this.layout = JSON.parse(layout);
            }
        }

        const menu = this.layout.main.find((item: any) => type === item.type);

        if (!menu) {
            return false;
        }

        return true;
    }

    getPages() {
        this.pageList = this.global.getOrCreateSessionData('pageList', []);
        this.pageService.getAllSections('footer').subscribe((res: any) => {
            this.pageList = [];
            res.data.map((section: any) => {
                section.pagesActive = section.pages.filter((pages: any) => {
                    return this.dictionary.footerPagesItems.some((item: any) => {
                        return item === pages.id;
                    });
                });
                if (section.pagesActive.length > 0) {
                    this.pageList.push(section);
                }
            });
        });
    }

    openModalWithTerms(privacy = false) {
        let data: any = {};
        if (privacy) {
            data.title = "Política de Privacidade";
            data.field = "appPrivacyTerm"
        }
        this.global.dialog.open(ModalTermsComponent, {
            width: '600px',
            data: data
        });
    }

    copyMessage(val) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.global.toast(`'${val}' copiado...`);
    }

}
