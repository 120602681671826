import { Component, Input, Renderer2, OnInit} from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';

@Component({
    selector: 'app-dynamic-sidebar',
    templateUrl: './dynamic-sidebar.component.html'
})

export class DynamicSidebarComponent implements OnInit {

   blockPanel: any;

    constructor(
        private render: Renderer2,
        public sharedService: SharedService
    ) {
        console.log(this.blockPanel);
    }

    ngOnInit() {
        this.sharedService.sharedData.subscribe((value) => {
            this.blockPanel = value;
            console.log(value);
        });
    }

    // checkEnable(e) {
    //     console.log(e);
    // }

    closeSidebar() {
        let sidebarEl = document.querySelector('.dynamicSidebar');
        let overlayEl = document.querySelector('.dynamicOverlay');
        // document.body.style.overflowX = "hidden";
        // document.body.style.overflowY = "scroll";
        this.render.removeClass(sidebarEl, 'active');
        setTimeout(() => {
            this.render.removeClass(overlayEl, 'active');
        }, 200);
        this.sharedService.nextData(null);
    }

}
