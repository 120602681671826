import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Global } from 'asap-crud';
import { Router } from '@angular/router';

@Injectable()
export class TrailService extends BaseService {

    constructor(
        public http: HttpClient,
        public global: Global,
        public router: Router
    ) {
        super(http,'api/v2','')
    }


    public related(id) {
        return this.getResources({router:'client/trilha/recomendada/' + id});
    }

    public trails() {
        return this.getResources({router:'client/trilha/livre'});
    }

    public trail(id) {
        return this.getResources({router:'admin/trail/item/' + id + '/info'});
    }

    public modules(id) {
        return this.getResources({router:'client/trilha/etapa/item/' + id});
    }

    public search(data) {
        return this.getResources({router:'client/trilha/livre/search?&search=' + data});
    }

}
