import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "asap-crud";

@Injectable()
export class CaptchaService extends BaseService {

    constructor(public http: HttpClient) {
      super(http);
      this.basePath = 'api/v2/client';
      this.entityName = 'captcha/get';
    }

    public getCaptcha() {
        return this.getResources();
    }

}
