import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Global, AsapService } from 'asap-crud';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { GroupService } from 'src/app/services/group.service';
import { AsapClientPipe } from 'asap-crud';
import { SharedService } from 'src/app/services/shared.service';
import { AuthService } from 'src/app/services/auth.service';
import { VoucherStore } from 'src/app/@core/store/voucher.store';
import { ThemeService } from 'src/app/services/theme.service';
import Swal from 'sweetalert2';
import { FacebookLoginProvider, SocialAuthService } from 'angularx-social-login';

@Component({
    selector: 'app-dynamic-login',
    templateUrl: './dynamic-login.component.html',
    providers: [AuthService, UserService, GroupService]
})
export class DynamicLoginComponent implements OnInit {

    @Output() closeSidebarEvent = new EventEmitter();

    isLoading: boolean;

    user = {
        email: null,
        password: null,
        field: null,
        value: null,
        voucher_id: null
    };

    socialData = null;

    profileArray: any;
    show: boolean = false;

    constructor(
        public authService: AuthService,
        public sharedService: SharedService,
        public global: Global,
        public service: AsapService,
        public userService: UserService,
        public groupService: GroupService,
        public router: Router,
        public socialAuthService: SocialAuthService,
        public voucherStore: VoucherStore,
        public activeRoute: ActivatedRoute,
        public theme: ThemeService
    ) {

    }

    public doLogon() {
        this.isLoading = true;

        if (this.voucherStore.state.isValid) {
            this.user.field = this.voucherStore.state.voucher.field;
            this.user.value = this.voucherStore.state.voucher.value;
            this.user.voucher_id = this.voucherStore.state.voucher.voucher_id;
        }

        this.authService.doLogon(this.user, false).then((success: any) => {
            console.log('DynamicLoginComponent: doLogin: success', success);

            if (success) {
                this.voucherStore.reset();
                this.closeSidebarEvent.emit();
                if (this.router.url == '/login') {
                    this.redirect();
                }
            } else {
                Swal.fire('Ops...', 'Nome de usuário e/ou senha incorretos', 'error');
            }

            this.isLoading = false;
            this.theme.waiting = false;
            this.theme.showHome = false;
            setTimeout(() => {
                this.theme.showHome = true;
            },100)
        });
    }

    public show_password() {
        this.show = !this.show;
    }

    ngOnInit() {
        this.getProfiles();
    }

    getProfiles() {
        this.groupService.getResources().subscribe((res: any) => {
            const groups = res.filter((item: any) => item.status);
            this.profileArray = [];
            groups.map((p: any) => {
                this.profileArray.push({
                    id: p.id,
                    name: p.name
                });
            });
        });
    }

    public socialAuth(groupId: any) {
        this.isLoading = true;
        this.socialData.grupo = groupId;

        if (this.voucherStore.state.isValid) {
            this.socialData.field = this.voucherStore.state.voucher.field;
            this.socialData.value = this.voucherStore.state.voucher.value;
        }

        this.authService.socialAuth(this.socialData).subscribe((data: any) => {
            if (data.access_token) {
                data.token = data.access_token;
                this.authService.setSessionToken(data).then((res: any) => {
                    this.redirect();
                });
            }
        }, err => {
            this.global.notification.warn('Houve um erro ao se cadastrar, tente novamente mais tarde');
            this.isLoading = false;
        });
    }

    socialAuthWithoutGroup() {
        this.isLoading = true;

        if (this.voucherStore.state.isValid) {
            this.socialData.field = this.voucherStore.state.voucher.field;
            this.socialData.value = this.voucherStore.state.voucher.value;
        }

        this.authService.socialAuth(this.socialData).subscribe((data: any) => {
            if (data.access_token) {
                data.token = data.access_token;
                this.authService.setSessionToken(data).then((res: any) => {
                    this.redirect();
                });
            }
        }, err => {
            this.global.notification.warn('Houve um erro ao se cadastrar, tente novamente mais tarde');
            this.isLoading = false;
        });
    }

    public facebookLogin() {
        this.isLoading = true;
        const socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
        this.socialAuthService.signIn(socialPlatformProvider).then(
            (userData) => {
                this.socialData = {
                    provider_user_id: userData.id,
                    provider: 'facebook',
                    picture: userData.photoUrl,
                    name: userData.name,
                    firstName: userData.firstName,
                    email: userData.email,
                    client_id: this.global.clientID()
                };
                if (this.profileArray.length === 0) {
                    this.socialAuthWithoutGroup();
                } else {
                    this.userService.groupsByField('email', userData.email).subscribe((response: any) => {
                        if (response.data.length > 0) {
                            this.socialAuth(response.data[0].group_id);
                        } else {
                            this.isLoading = false;
                        }
                    });
                }
            },
            (error) => {
                this.isLoading = false;
                this.global.notification.error('Houve um erro ao se cadastrar, tente novamente mais tarde');
            }
        );
    }

    public redirect() {
        const sdRequired = AsapClientPipe.dictionary.supplementaryDataRequired;

        const check = localStorage.getItem('supplementary_data_check');
        const lu = localStorage.getItem('loggedUser');

        this.closeSidebarEvent.emit();

        if (lu && sdRequired && check === '0') {
            this.router.navigate(['/dados-complementares']);
        } else {
            this.router.navigate(['/portal']);
        }
    }

}
