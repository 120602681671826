import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Global } from 'asap-crud';
import { Router } from '@angular/router';

@Injectable()
export class PageService extends BaseService {

  public pageList;

  constructor(
    public http: HttpClient,
    public global: Global,
    public router: Router
  ) {
    super(http, 'api/v2/client', 'page');
    this.getPages();
  }

  getAllSections(source) {
    // GET https://api.tecnologiaasap.com.br/api/v2/client/pages-section/content/all
    return this.http.get(this.host + '/api/v2/client/pages-section/content/all?client_id=' + this.client, this.headers());
  }

  get client(): string {
    return localStorage.getItem('client');
  }

  getPages() {
    this.pageList = this.global.getOrCreateSessionData('pageList', []);
    this.getAllSections('navbar').subscribe((res: any) => {
      res.data.forEach(item => {
        this.pageList.push(item);
      });
    });
  }

}
