import { Component, OnInit } from '@angular/core';
import { PageService } from 'src/app/services/pages.service';
import { ActivatedRoute } from '@angular/router';
import { AsapService } from 'asap-crud';

@Component({
  selector: 'app-faq',
  templateUrl: './page.component.html',
  providers: [PageService]
})
export class PageComponent implements OnInit {

  page: any;
  slug: string;
  loading: boolean;

  constructor(
    public activatedRoute: ActivatedRoute,
    // public pageService: PageService
    public service: AsapService
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((param: any) => {
      this.slug = param.slug;
      this.getPage();
    });
  }

  getPage() {
    this.loading = true;

    this.service.basePath = 'api/v2/client';
    this.service.entityName = `page?slug=${this.slug}`;
    this.service.getResources().subscribe(
      (response => {
        // this.page = response.data.find((item: any) => item.slug == this.slug);
        this.page = response.data;
        this.loading = false;
      }),
      error => {
        this.loading = false;
      })
    // .subscribe((res: any) => {
    //   this.page = res.data.find((item: any) => item.slug === this.slug);
    //   this.loading = false;
    // });
  }


}
