import { NgModule } from '@angular/core';
import { AsapCrudModule } from 'asap-crud';
import { AsapImagePipeModule } from 'asap-image-pipe';

import { SafeUrlPipe } from './safe-url.pipe';
import { StreamPipe } from './stream.pipe';

@NgModule({
    imports: [
      AsapImagePipeModule,
      AsapCrudModule,
    ],
    declarations: [
      SafeUrlPipe,
      StreamPipe
    ],
    exports: [
      AsapImagePipeModule,
      AsapCrudModule,
      SafeUrlPipe,
      StreamPipe
    ]
})

export class PipesModule {}
