import { EventEmitter, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable({
    providedIn: 'root'
})
export class PwaUpdateService {

    public update = new EventEmitter();

    constructor(
        private readonly updates: SwUpdate,
    ) {
        console.warn('[PwaUpdateService] Monitoring update started...');
        if (this.updates.isEnabled) {
            console.warn('[PwaUpdateService] Update check enabled...');
            this.updates.available.subscribe(() => {
                console.warn('[PwaUpdateService] Update found...');
                this.update.emit();
            });
        }
        else {
            console.warn('[PwaUpdateService] Update check not enabled...');
        }
    }
}
