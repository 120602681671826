
import { Routes } from '@angular/router';
import { AuthComponent } from './auth.component';

export const AuthRoutes: Routes = [
    {
      path: '',
      component: AuthComponent,
      children: [
        {
            path: 'login',
            loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
        },
        {
            path: 'recovery',
            loadChildren: () => import('./recovery/recovery.module').then(m => m.RecoveryModule)
        },
        {
          path: 'register/:type',
          loadChildren: () => import('./user/user.module').then(m => m.UserModule)
        }
      ]
    }
];
