import { AsapBaseService } from 'asap-crud';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class PanelMentoringService extends AsapBaseService {

	constructor(public http: HttpClient) {
		super(http);
		this.basePath = "api/v2/client";
		this.entityName = "painel-mentoria";
	}
	

}
