import { Routes } from '@angular/router';
import { SchoolComponent } from './school.component';
import { PlatformGuardService } from '../services/platform-guard.service';
import { SchoolSearchComponent } from './search/school-search.component';
import { PageComponent } from './page/page.component';
import { ViewAllComponent } from 'asap-shared';
import { PanelMentoringRoutes } from 'asap-panel-mentoring';
import { StaticPageComponent } from './static-page/static-page.component';
import { SchoolTimelineComponent } from './timeline/school-timeline.component';
import { mentoriaRoute, MentoriaRoutes } from 'asap-mentoria';
import { AssessmentRoutes } from 'asap-assessment';
import { AsapCurriculumRoutes } from 'asap-curriculum';

export const multicontaRoute = (company) => {
    let tk = '';
    const urls = company.urls;
    const myURL = window.location.hostname;
    const token = localStorage.getItem('token');
    let route = '';
    if (urls && urls.length) {
        if (company.id == localStorage.getItem('client')) {
            route = '/';
        }
        else {
            if (token) {
                tk = '?foreign=' + token.split(' ')[1];
            }
            if (myURL.includes('hmgasap')) {
                const url = urls.find(u => u.includes('hmgasap'));
                if (url) {
                    route = 'https://' + url + tk;
                }
                else {
                    const url = urls.find(u => u.includes('asap'));
                    if (url) {
                        route = 'https://' + url + tk;
                    }
                }
            }
            else {
                const url = urls.find(u => !u.includes('asap'));
                if (url) {
                    route = 'https://' + url + tk;
                }
            }
        }

        if (route == '') { route = 'https://' + urls[0] + tk; }
    }

    window.location.href = route;
};

export const SchoolRoutes: Routes = [
    {
        path: '',
        component: SchoolComponent,
        children: [
            {
                path: 'assessment',
                children: AssessmentRoutes
            },
            {
                path: 'plans',
                loadChildren: () => import('../portal/plans/portal-plans.module').then(m => m.PortalPlansModule),
            },
            // {
            //     path: 'assessment/:id/:journey_id/:journey_item_id',
            //     component: SchoolAssessmentComponent,
            //     data: {
            //         needLogon: true
            //     }
            // },
            // {
            //     path: 'report/:id',
            //     component: SchoolAsessmentReportComponent,
            //     data: {
            //         needLogon: true
            //     }
            // },
            {
                path: 'agenda',
                data: { module: 'elearning', needLogon: true },
                canLoad: [PlatformGuardService],
                children: MentoriaRoutes
            },
            {
                path: 'curriculum/:id',
                data: { module: 'elearning' },
                canLoad: [PlatformGuardService],
                children: AsapCurriculumRoutes
            },
            {
                path: 'panel-mentoring',
                data: { module: 'elearning' },
                canLoad: [PlatformGuardService],
                children: PanelMentoringRoutes
            },
            {
                path: 'timeline/:id',
                component: SchoolTimelineComponent,
                data: {
                    needLogon: true
                }
            },
            {
                path: 'tutor',
                data: { module: 'elearning' },
                canLoad: [PlatformGuardService],
                loadChildren: () => import('./tutor-panel/tutor-panel.module').then(m => m.TutorPanelModule)
            },
            {
                path: 'level',
                data: { module: 'elearning' },
                canLoad: [PlatformGuardService],
                loadChildren: () => import('./level/school-level.module').then(m => m.SchoolLevelModule)
            },
            {
                path: '',
                data: { module: 'elearning' },
                canLoad: [PlatformGuardService],
                loadChildren: () => import('../school/mini-visualizador-gateway/mini-visualizador-gateway.module').then(m => m.MiniVisualizadorGatewayModule)
            },
            {
                path: 'journey',
                data: { module: 'elearning' },
                canLoad: [PlatformGuardService],
                // loadChildren: () => import('./public/journey/journey.module').then(m => m.JourneyModule)
                loadChildren: () => import('../public/journey/journey.module').then(m => m.JourneyModule)
            },
            {
                path: 'faq',
                data: { module: 'elearning' },
                canLoad: [PlatformGuardService],
                // component: FaqComponent
                loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule)
            },
            {
                path: 'search',
                data: { module: 'elearning' },
                canLoad: [PlatformGuardService],
                component: SchoolSearchComponent
            },
            {
                path: 'page/:slug',
                data: { module: 'elearning' },
                canLoad: [PlatformGuardService],
                component: PageComponent
            },
            {
                path: 'static/:slug',
                data: { module: 'elearning' },
                canLoad: [PlatformGuardService],
                component: StaticPageComponent
            },
            {
                path: 'videos',
                loadChildren: () => import('../portal/videos/videos.module').then(m => m.VideosModule)
            },
            {
                path: 'order',
                loadChildren: () => import('../market/product/order-b2b/market-order-b2b.module').then(m => m.MarketOrderB2BModule)
            },
            {
                path: 'all/videos',
                component: ViewAllComponent,
                data: {
                    basePath: 'api/v2/client',
                    entityName: 'gallery/full/video',
                    fieldSearchBy: 'title',
                    fieldTitle: 'title',
                    fieldImage: 'image',
                    title: 'Vídeos',
                    subTitle: 'Listagem de Vídeos',
                    routing: '/videos'
                }
            },
            {
                path: 'all/training',
                component: ViewAllComponent,
                data: {
                    basePath: 'api/v2/client',
                    entityName: 'trilha/geral/tipo/web',
                    fieldCategory: 'pasta_id',
                    fieldTitle: 'titulo',
                    fieldDataRequest: 'trilhas',
                    fieldDescription: 'descricao',
                    fieldImage: 'arquivo',
                    title: 'Treinamentos',
                    fieldSearchBy: 'trilha_itens.titulo',
                    subTitle: '',
                    routing: '/training',
                    showButtom: true,
                    salesButtonShow: true
                }
            },
            {
                path: 'all/courses',
                component: ViewAllComponent,
                data: {
                    basePath: 'api/v2/client',
                    entityName: 'trilha/geral/tipo/web/session' + window.location.search,
                    fieldCategory: 'pasta_id',
                    fieldTitle: 'titulo',
                    fieldDescription: 'descricao',
                    fieldImage: 'arquivo',
                    title: 'Cursos por Sessão',
                    fieldSearchBy: 'trilha_itens.titulo',
                    subTitle: '',
                    routing: '/courses',
                    showButtom: true,
                    salesButtonShow: true
                }
            },
            {
                path: 'all/connections',
                component: ViewAllComponent,
                data: {
                    needLogon: true,
                    basePath: 'api/v2/admin',
                    entityName: 'user/search',
                    fieldTitle: 'name',
                    fieldSubtitle: 'email',
                    fieldImage: 'picture',
                    title: 'Usuários',
                    fieldSearchBy: 'users.name',
                    hasCategory: true,
                    categorySearchField: 'Áreas de Interesse',
                    categoryLabel: 'Áreas de Interesse',
                    categoryBasePath: 'api/v2/admin',
                    categoryEntityName: 'folder/permission/list?filter=Áreas de Interesse',
                    categoryDataRequest: '0.data.options',
                    fieldCategoryDescription: false,
                    subTitle: '',
                    routing: '/profile',
                    showButtom: true,
                    coverClasses: 'avatar'
                }
            },
            {
                path: 'all/multicontas',
                component: ViewAllComponent,
                data: {
                    basePath: 'api/v2/admin',
                    entityName: 'asap-client/vinculo/' + localStorage.getItem('client'),
                    fieldTitle: 'name',
                    fieldImage: 'image',
                    title: 'Comunidades',
                    fieldSearchBy: 'nome',
                    enableSearch: false,
                    subTitle: '',
                    click: multicontaRoute,
                    showButtom: true
                }
            },
            {
                path: 'all/events',
                component: ViewAllComponent,
                data: {
                    basePath: 'api/v2/client',
                    entityName: 'event',
                    fieldTitle: 'name',
                    fieldSubtitle: 'description',
                    fieldDescription: false,
                    fieldImage: 'banner',
                    title: 'Eventos',
                    queryParams: 'type=presential',
                    fieldSearchBy: 'events.name',
                    subTitle: '',
                    // click: eventRoute,
                    showButtom: true,
                    routing: '/events/details',
                    //useHREF: true
                }
            },
            {
                path: 'all/forum-theme',
                component: ViewAllComponent,
                data: {
                    basePath: 'api/v2/client',
                    entityName: 'forum-chat',
                    fieldTitle: 'question',
                    icon: 'fa fa-slack',
                    fieldDescription: false,
                    fieldSubtitle: 'description',
                    title: 'Foruns Temáticos',
                    fieldSearchBy: 'question',
                    routing: '/message/forum',
                    subTitle: '',
                    enableSearch: false,
                    showButtom: true
                }
            },
            {
                path: 'all/mentoring',
                component: ViewAllComponent,
                data: {
                    basePath: 'api/v2/client',
                    entityName: 'user-mentor',
                    fieldTitle: 'name',
                    fieldImage: 'picture',
                    fieldDescription: false,
                    fieldSubtitle: 'vacancy',
                    title: 'Mentoria',
                    click: mentoriaRoute,
                    subTitle: '',
                    enableSearch: false,
                    showButtom: true
                }
            }
        ]
    },
];
