import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { PipesModule } from "src/app/@core/pipes/pipes.module";
import { PagesNavbarModule } from "../pages-navbar/pages-navbar.module";
import { FooterComponent } from "./footer.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    PagesNavbarModule
  ],
  declarations: [
    FooterComponent
  ],
  exports: [
    FooterComponent
  ],
  entryComponents: [
    
  ]
})

export class FooterModule {
  constructor() {
    console.log('[FooterModule]', new Date());
  }
}
