import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from 'asap-crud';

@Component({
    selector: 'app-top-mobile',
    templateUrl: './top-mobile.component.html'
})

export class TopMobileComponent {

  constructor(
      public global: Global,
      public router: Router
  ) {

  }

  public doLogout() {
      this.global.notification.success('Desconectado', 'Você foi desconectado com sucesso!');
      localStorage.clear();
      location.href = '/portal';
  }

}
