import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Global } from 'asap-crud';
import { AsapClientPipe } from 'asap-crud';
import { PageService } from 'src/app/services/pages.service';
import { ThemeService } from 'src/app/services/theme.service';
import { VoucherStore } from 'src/app/@core/store/voucher.store';

declare const $: any;

@Component({
    selector: 'app-sidemenu',
    templateUrl: './sidemenu.component.html',
    providers: [PageService],
})

export class SideMenuComponent implements OnInit {

    public layout;
    public dictionary = AsapClientPipe.dictionary;

    pageList: any;
    pageNavbarList: any;

    navbarSectionLinks = {
        news: {
            label: 'Notícias',
            link: '/news/view/all'
        },
        trail: {
            label: 'Cursos',
            link: '/training'
        }
    };

    constructor(
        public voucherStore: VoucherStore,
        public theme: ThemeService,
        public router: Router,
        public global: Global,
        public pageService: PageService
    ) {
        this.theme.getDesign().subscribe((res: any) => {
            if (res.data) {
                this.layout = res.data.value;
            }
        });
    }

    ngOnInit() {
        this.getPages();

        $(document).ready(() => {

            $('.menu li a').on('click', () => {
                $('.menu li a.current').removeClass('current');
                $('.sidemenu, .content, .overlay').toggleClass('active');
                $(this).addClass('current');
            });

            $('.sidemenuCollapse').on('click', () => {
                $('.sidemenu, .content, .overlay').toggleClass('active');
            });

            $('.overlay').on('click', () => {
                $('.sidemenu, .content, .overlay').removeClass('active');
            });

            $('.components').on('click', () => {
                $('.sidemenu, .content, .overlay,').removeClass('active');
            });

            $('.navbar-brand').on('click', () => {
                $('.sidemenu, .content, .overlay,').removeClass('active');
            });

        });

        // this.pageService.getResources().subscribe((res: any) => {
        //     this.pageList = res.data.filter((pages: any) => {
        //         return this.dictionary.navbarPagesItems.some((item: any) => {
        //             return item === pages.id;
        //         });
        //     });
        // });
    }

    public findText(type) {
        if (!this.layout) {
            const layout = localStorage.getItem('layout_home');
            if (layout) {
                this.layout = JSON.parse(layout);
            }
        }
        if (this.layout) {
            const menu = this.layout.main.find((item: any) => type === item.type);

            if (menu && menu.title && menu.title.text) {
                const textMenu = menu.title.text.toLowerCase();
                return textMenu.charAt(0).toUpperCase() + textMenu.slice(1);
            } else {
                return false;
            }
        }
        // return text;
    }

    public findComponent(type: string) {
        let menu;
        if (!this.layout) {
            const layout = localStorage.getItem('layout_home');
            if (layout) {
                this.layout = JSON.parse(layout);
            }
        }
        if (this.layout && this.layout.main) {
            menu = this.layout.main.filter((item: any) => type === item.type);
        }

        if (!menu) {
            return false;
        }

        return menu;
    }

    public getTitle(component: any, titleDefault: string) {
        if (component && component.title && component.title.text) {
            const textMenu = component.title.text.toLowerCase();
            return textMenu.charAt(0).toUpperCase() + textMenu.slice(1);
        }
        return titleDefault;
    }

    public openVacancies() {
        if (!this.global.loggedUser()) {
            this.router.navigate(['/login']);
        } else {
            this.router.navigate(['/vacancies']);
        }
    }

    public doLogout() {
        this.theme.waiting = true;
        this.global.notification.success('Desconectado', 'Conta desconectada com sucesso');

        // localStorage.removeItem('token');
        // localStorage.removeItem('supplementary_data_check');
        // localStorage.removeItem('loggedUser');
        // localStorage.removeItem('plainToken');
        localStorage.clear();
        this.voucherStore.reset();

        window.location.href = '/';
    }

    public getPages() {
        this.pageList = this.global.getOrCreateSessionData('pageList',[]);
        // this.pageService.getAllSections('sidemenu').subscribe((res: any) => {
        //     this.pageNavbarList = res.data;
        // });
    }

    public getPage(page: number) {
        const find = this.pageNavbarList.find((item: any) => +item.id === +page);
        return find;
    }

    public sidemenuToggle() {
        $('.sidemenu, .content, .overlay').toggleClass('active');
    }


}
