import { DeviceDetectorService } from 'ngx-device-detector';
import { AsapService, Global } from 'asap-crud';
import { Component, ViewChild } from '@angular/core';
import { ThemeService } from './services/theme.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PwaUpdateService } from './services/pwa-update.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  // providers: [AuthService, ClienteService]
})

export class AppComponent {

  @ViewChild('asapChat') asapChat;

  public isMobile;

  constructor(
    public theme: ThemeService,
    public router: Router,
    public route: ActivatedRoute,
    public global: Global,
    public deviceDetector: DeviceDetectorService,
    public asapService: AsapService,
    public pwaUpdate: PwaUpdateService
  ) {
    console.log('[AppComponent]', new Date());
    this.pwaUpdate.update.subscribe(() => {
      this.global.startWaiting('Atualização encontrada, reiniciando o sistema em 3 segundos!');
      setTimeout(() => {
        window.location.reload(true);
      }, 3000);
    });
    this.isMobile = this.deviceDetector.isMobile();

    const chatTimer = setInterval(() => {
      if (this.asapChat) {
        clearInterval(chatTimer);
        this.global.setSessionData('asap-chat', this.asapChat);
      }

    }, 500); ;

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        let snap = this.route.snapshot;
        do {
            const data = snap.data;
            console.log('[AppComponent] snapshot', data, snap);
            snap = snap.firstChild;
            if (data.needLogon != null) {
              if (data.needLogon && !this.global.loggedUser()) {
                this.router.navigate(['/login'], {queryParams: {url: router.url}});
                this.global.stopWaiting();
              }
              snap = null;
            }
        } while (snap);
      }
    });


  }


  public scrollTop(event) {
    window.scroll(0, 0);
  }

}
