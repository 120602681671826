import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { PipesModule } from "src/app/@core/pipes/pipes.module";
import { PagesNavbarComponent } from "./pages-navbar.component";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule
  ],
  declarations: [
    PagesNavbarComponent
  ],
  exports: [
    PagesNavbarComponent
  ],
  entryComponents: [
    
  ]
})

export class PagesNavbarModule {
  constructor() {
    console.log('[PagesNavbarModule]', new Date());
  }
}
