import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { Global } from 'asap-crud';
import { Router } from '@angular/router';

@Injectable()
export class UserService extends BaseService {

    constructor(
        public http: HttpClient,
        public global: Global,
        public router: Router
    ) {
        super(http,'api/v2','')
    }

    public groupsByField(field,value) {
        return this.createResource({value:value},{router:'users/groups/' + field});
    }

    public about() {
        return this.getResources({router:'perfil/sobre'});
    }

    public trails() {
        return this.getResources({router:'client/trilha'});
    }

    public trailsWithSeal() {
        return this.getResources({router: 'client/trilha'});
    }

    public topUsers() {
        return this.getResources({router:'client/gamefication/top-rank/4'});
    }

    public trailSumary() {
        return this.getResources({router:'client/trilhaInfo'});
    }

    public storeSumary() {
        return this.getResources({router:'loja/dados'});
    }

    public trailList() {
        return this.getResources({router:'client/trilha'});
    }

    public orderList() {
        return this.getResources({router:'loja/pedido'});
    }

    public updateProfile(data) {
        return this.updateResource(data, {router:'perfil/sobre'});
    }

    public updatePassword(data: any) {
      return this.updateResource(data, {router: 'perfil/sobre'});
    }

}
