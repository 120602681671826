import { Injectable } from '@angular/core';
import { Store } from './store';

export class WishlistState {
  courses = [];
  total = 0;
}

@Injectable({providedIn: 'root'})
export class WishlistStore extends Store<WishlistState> {
  constructor() {
    super(new WishlistState());
  }

  addCourse(course: any): void {

    const find = this.state.courses.find(item => item.id === course.id);

    if (find) {
      return;
    }

    const coursePrice = (course.price) ? course.price.ValorTotal : 0;

    this.setState({
      ...this.state,
      courses: [...this.state.courses, course],
      total: this.state.total + coursePrice
    });
  }

  removeCourse(course: any): void {
    const newStateCourses = this.state.courses.filter(item => item.id !== course.id);
    const coursePrice = (course.price) ? course.price.ValorTotal : 0;

    this.setState({
      ...this.state,
      courses: newStateCourses,
      total: this.state.total - coursePrice
    });
  }

  reset() {
    this.setState(new WishlistState());
  }
}
