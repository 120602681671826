import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-portal-footer',
  templateUrl: './portal-footer.component.html'
})

export class PortalFooterComponent {

}
