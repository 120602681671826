import { Routes } from '@angular/router';

export const AppRoutes: Routes = [

  {
    path: '',
    loadChildren: () => import('./lazyload/lazyload.module').then(m => m.LazyLoadModule)
  },
  {
    path: 'training/:id',
    redirectTo: 'school/timeline/:id'
  },
  {
    path: 'agenda',
    redirectTo: 'school/agenda/definir'
  },
  {
    path: 'courses',
    pathMatch: 'prefix',
    redirectTo: 'school/timeline'
  },
  {
    path: 'videos',
    pathMatch: 'prefix',
    redirectTo: 'school/videos'
  },
  {
    path: 'agendamento',
    pathMatch: 'prefix',
    redirectTo: 'school/agenda/agendamento'
  },
  {
    path: 'visualizador',
    pathMatch: 'prefix',
    redirectTo: 'school/visualizador'
  },
  {
    path: 'dynamic',
    pathMatch: 'prefix',
    redirectTo: 'school/dynamic'
  },
  {
    path: 'static',
    pathMatch: 'prefix',
    redirectTo: 'school/static'
  },
  {
    path: 'curriculum',
    pathMatch: 'prefix',
    redirectTo: 'school/curriculum'
  },
  {
    path: 'journey',
    pathMatch: 'prefix',
    redirectTo: 'school/journey'
  },
  {
    path: 'jornada/:id',
    pathMatch: 'prefix',
    redirectTo: 'school/journey/:id'
  },
  {
    path: 'jornada',
    pathMatch: 'prefix',
    redirectTo: 'school/jornada'
  },
  {
    path: 'assessment',
    pathMatch: 'prefix',
    redirectTo: 'school/assessment'
  },
  {
    path: 'mini-visualizador',
    pathMatch: 'prefix',
    redirectTo: 'school/mini-visualizador'
  },
  {
    path: 'faq',
    pathMatch: 'prefix',
    redirectTo: 'school/faq'
  },
  {
    path: 'school/assessment',
    pathMatch: 'prefix',
    redirectTo: 'school/assessment/open'
  },
  {
    path: 'school/report',
    pathMatch: 'prefix',
    redirectTo: 'school/assessment/report'
  },
  {
    path: 'all/mentoring',
    pathMatch: 'prefix',
    redirectTo: 'school/all/mentoring'
  },
  {
    path: 'payment/plans/:id',
    pathMatch: 'prefix',
    redirectTo: 'school/plans'
  },
  {
    path: 'universidade',
    pathMatch: 'prefix',
    redirectTo: 'school'
  }
];
