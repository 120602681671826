import { Injectable } from '@angular/core';
import { BaseService } from "asap-crud";
import { HttpClient } from '@angular/common/http';
import { Global } from 'asap-crud';
import { Router } from '@angular/router';

@Injectable({providedIn: 'root'})
export class GroupService extends BaseService {

    constructor(
        public http: HttpClient,
        public global: Global,
        public router: Router
    ) {
        super(http);
        this.basePath = 'api/v2/client';
        this.entityName = 'lista/grupos';
    }

  }