import { Injectable } from '@angular/core';
import 'rxjs/add/observable/of';
import { BaseService } from 'asap-crud';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ClienteService extends BaseService {

    constructor(
        public http: HttpClient,
    ) {
        super(http);
        this.basePath = 'api/v2';
        this.entityName = '';
    }


    getColorByHost(host): Observable<any> {
        const data = { url: host };
        return this.createResource(data, {router: 'admin/color/host'});
    }

    getColorByHostFile(host):  Observable<any> {
        const clientID = 20;
        const url = `https://file.plataformaasap.com.br/json/client-${clientID}.json`;
        return this.http.get(url);
    }

}
