import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalLgpdComponent } from '../modal-lgpd/modal-lgpd.component';
import { ThemeService } from '../services/theme.service';

@Component({
  selector: 'app-school',
  templateUrl: './school.component.html'
})

export class SchoolComponent {

  constructor(
    public theme: ThemeService,
    public dialog: MatDialog
  ) {

  }

  ngOnInit(): void {
    this.loadLGPD();
  }

  loadLGPD() {
    const checkAssign = localStorage.getItem("lgpdAssing");
    if (!checkAssign) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.position = {
        bottom: '0',
        left: '0',
        right: '0'
      }
      this.dialog.open(ModalLgpdComponent, {
        width: '100vw',
        maxWidth: '100vw',
        closeOnNavigation: false,
        disableClose: true,
        backdropClass: 'custom-global-overlay-wrapper',
        hasBackdrop: true,
        position: dialogConfig.position
      });
    }
  }

}
