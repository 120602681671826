import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Global } from 'asap-crud';
import { Router } from '@angular/router';


@Injectable()
export class CartService extends BaseService {

    constructor(
        public http: HttpClient
    ) {
        super(http, 'api/v2', 'loja');
    }

    public order(data) {
        data.cart = this.cart();
        return this.createResource(data, {router: 'carrinho/pagamento'});
    }

    public cart() {

        const cart = localStorage.getItem('shopping-cart');
        if (cart) {
            return JSON.parse(cart);
        }

        return [];
    }

    public addItem(id, type, picture, description, value, installment, checkout) {
        const cart = this.cart();
        cart.push({
            id,
            type,
            picture,
            description,
            value,
            installment: (installment) ? installment : 0,
            checkout
        });

        localStorage.setItem('shopping-cart', JSON.stringify(cart));
    }

    public removeItem(idx) {
        const cart = this.cart();

        cart.splice(idx, 1);

        localStorage.setItem('shopping-cart', JSON.stringify(cart));
    }

    public empty() {
        localStorage.removeItem('shopping-cart');
    }

    public tokenMercadoPago(order, description, quantity, value, url = null) {
        const params: any = {router: 'mercado-pago/token'};
        const clientID = localStorage.getItem('client');

        if (clientID) {
            params.query = `client_id=${clientID}`;
        }

        const data = {
            order,
            description,
            quantity,
            value,
            url
        };

        return this.createResource(data, params);
    }

    public tokenPagSeguro(order, description, quantity, value) {
        const data = {
            order,
            description,
            quantity,
            value
        };

        return this.createResource(data, {router: 'pag-seguro/token'});
    }

}
