import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AsapImagePipeModule } from "asap-image-pipe";
import { PipesModule } from "src/app/@core/pipes/pipes.module";
import { NavbarMobileComponent } from "../navbar-mobile/navbar-mobile.component";
import { NavbarComponent } from "./navbar.component";
import { AsapNotificationsModule } from 'asap-notifications';
import { TranslateSharedModule } from "src/app/translate.module";
import { MomentModule } from "ngx-moment";
import { PagesNavbarModule } from "../pages-navbar/pages-navbar.module";
import { PageService } from "src/app/services/pages.service";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AsapImagePipeModule,
    AsapNotificationsModule,
    TranslateSharedModule,
    MomentModule,
    PipesModule,
    PagesNavbarModule
  ],
  declarations: [
    NavbarComponent,
    NavbarMobileComponent,
  ],
  exports: [
    PagesNavbarModule,
    AsapNotificationsModule,
    NavbarComponent,
    NavbarMobileComponent
  ],
  providers: [
    PageService
  ],
  entryComponents: [
    
  ]
})

export class NavbarModule {
  constructor() {
    console.log('[NavbarModule]', new Date());
  }
}
