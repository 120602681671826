import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

export class BaseService {
  
    constructor(
        public http: HttpClient,
        public basePath: string,
        public entityName: string
    ) {

    }

    protected host: string = environment.apiURL;

    protected token() {
        return localStorage.getItem('token');
    }
    
    protected headers(){ 
        let header;

        if (this.token()) {
            header = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': this.token(),
                'Access-Control-Allow-Origin': '*'
            });
        }
        else {
            header = new HttpHeaders({
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            });
        }

        return header;
    }

    protected options() {
        return {
            headers: this.headers()
        };
    }

    public getResources(params = {}) {
        return this.http.get(this.resourceUrl(null, params), this.options());
    }

    public getResource(id, params = {}) {
        return this.http.get(this.resourceUrl(id, params), this.options());
    }

    public createResource(data, params = {}) {
        return this.http.post(this.resourceUrl(null, params), JSON.stringify(data), this.options());
    }
    
    public updateResource(data, params = {}) {
        return this.http.put(this.resourceUrl(data.id, params), JSON.stringify(data), this.options());
    }

    public deleteResource(id) {
        return this.http.delete(this.resourceUrl(id), this.options());
    }

    public resourceUrl(id = null, params = {}) {

        const endpoint = [
            this.host,
            this.basePath,
            this.urlParameters(params),
            this.entityName,
            id
        ].filter(element => element != false).join('/').replace(/\/$/, '')

        return endpoint + this.queryString(params)
    }

    protected urlParameters(params) {
        var urlParameters = []

        for(var placeholder in params)
        if (/.*_id$/.test(placeholder))
            urlParameters.push(`${placeholder}/${params[placeholder]}`)

        return urlParameters.join('/')
    }

    protected queryString(params) {
        let query = '';

        if (params.router){
            query = `/${params.router}`;   
        }

        if (params.query){
            query += `?${params.query}`;
        }

        return query;
    }


}
